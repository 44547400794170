import styled from 'styled-components';

export const StyledRoot = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
`;

export const StyledContentLogo = styled('div')({
  display: 'flex',
  justifyContent: ' center',
  alignItems: 'center',
  padding: '16px 0px 16px 0px',
  boxSizing: 'border-box',
});

export const StyledLogo = styled('img')({
  width: 200,
});
