/* eslint-disable no-plusplus */
import React, { ReactElement, useMemo, useState } from 'react';

import { MenuItem, Select } from '@mui/material';
import { StyledItemCategory } from './styles';
import { formatOnlyHour } from '../../utils/date';

interface ListAreasProps {
  areas: any[];
  event: any;
}

const ListAreas: React.FC<ListAreasProps> = ({ areas }): ReactElement => {
  const [daySelected, setDaySelected] = useState('0');

  const formatName = (item: any): string => {
    return item.categoryName;
  };

  const countNumberFight = (categories: any[]): number => {
    return categories.reduce(
      (prev, current) => {
        return Number(prev) + Number(current.numberFights || 0);
      },
      [0]
    );
  };

  const days = useMemo(() => {
    const itemDays = areas.map((item) => item.day);

    const result = [...new Set(itemDays)];

    return result.sort();
  }, [areas]);

  const filterByDay = (items: any[], day: number): any[] => {
    return items.filter((item) => item.day === day);
  };

  const handleClick = (item: any) => {
    window.open(`${window.location.href}/brackets/${item.uuid}`, '_blank');
  };

  const sortArrayV2 = (array: any[]): any[] => {
    if (!array?.length) {
      return [];
    }
    return array.sort((a, b) => {
      const timeA = new Date(a.date);
      const timeB = new Date(b.date);
      return timeA.getTime() - timeB.getTime();
    });
  };

  const getFisrtFightDate = (fights: any[], numberFights: number): string => {
    if (!numberFights || !fights.length) {
      return 'xx:xx';
    }
    const fightsSorted = sortArrayV2(fights);
    return formatOnlyHour(fightsSorted[0].date);
  };

  const getFistDate = (categories: any[]): string => {
    if (!categories.length) {
      return '0 min';
    }
    const firstCategory = categories.find((item) => item.order === 0);

    if (!firstCategory) {
      return '';
    }

    const fisrtFightsSorted = sortArrayV2(firstCategory.fights);

    const firstFight = fisrtFightsSorted[0];

    if (!firstFight) {
      return '';
    }

    return formatOnlyHour(firstFight.date);
  };

  const getFinalEta = (categories: any[]): string => {
    if (!categories.length) {
      return 'xx:xx';
    }
    const lastCategory = categories.find(
      (item) => item.order === categories.length - 1
    );

    if (!lastCategory) {
      return '';
    }

    const fightsSorted = sortArrayV2(lastCategory.fights);

    if (!fightsSorted.length) {
      return '';
    }

    return formatOnlyHour(fightsSorted[fightsSorted.length - 1].date);
  };

  const differenceInMinutesCeil = (date1: Date, date2: Date): number => {
    const differenceInMilliseconds = Math.abs(
      new Date(date2).getTime() - new Date(date1).getTime()
    );

    const differenceInMinutes = Math.ceil(
      differenceInMilliseconds / (1000 * 60)
    );

    return differenceInMinutes;
  };

  const formatMinutesToTime = (minutes: number): string => {
    if (minutes < 60) {
      return `${minutes} min`;
    }
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    if (remainingMinutes === 0) {
      return `${hours} ${hours === 1 ? 'hora' : 'horas'}`;
    }
    return `${hours} ${
      hours === 1 ? 'hora' : 'horas'
    } e ${remainingMinutes} min`;
  };

  const getTimeTotal = (categories: any[]): string => {
    if (!categories.length) {
      return '0 min';
    }
    const firstCategory = categories.find((item) => item.order === 0);
    const lastCategory = categories.find(
      (item) => item.order === categories.length - 1
    );

    if (!firstCategory || !lastCategory) {
      return '';
    }

    const fisrtFightsSorted = sortArrayV2(firstCategory.fights);
    const lastFightsSorted = sortArrayV2(lastCategory.fights);

    const firstFight = fisrtFightsSorted[0];
    const lastFight = lastFightsSorted[lastFightsSorted.length - 1];

    if (!firstFight || !lastFight) {
      return '';
    }

    const diff = differenceInMinutesCeil(lastFight.date, firstFight.date);

    return `${formatMinutesToTime(diff)}`;
  };

  const renderObjectDay = useMemo(() => {
    if (typeof daySelected !== 'number') {
      return [days[0]];
    }

    return days.filter((item) => Number(item) === Number(daySelected));
  }, [days, daySelected]);

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        padding: '0px 24px 24px 24px',
        boxSizing: 'border-box',
      }}
    >
      <div style={{ textAlign: 'center', fontSize: 18, paddingBottom: 24 }}>
        Áreas
      </div>

      <div style={{}}>
        <Select
          value={daySelected}
          onChange={(event) => setDaySelected(event.target.value)}
          style={{ width: 320, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}
        >
          {days.map((item) => (
            <MenuItem key={item} value={item}>
              Dia {item + 1}
            </MenuItem>
          ))}
        </Select>
      </div>

      {renderObjectDay.map((day) => (
        <React.Fragment key={day}>
          <div
            style={{
              width: '100%',
              overflowX: 'auto',
              display: 'flex',
              gap: 18,
              paddingTop: 48,
            }}
          >
            {filterByDay(areas, day)
              // .filter((item) => item.categories.length)
              .map((item, index) => (
                <div key={index} style={{ minWidth: 300, width: 300 }}>
                  <div>{item.name}</div>
                  <div
                    style={{
                      border: '1px solid #ccc',
                      background: '#fff',
                      borderRadius: 8,
                      width: 300,
                      height: 'auto',
                      marginTop: 12,
                      boxSizing: 'border-box',
                      boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                      minHeight: 100,
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        width: 300,
                        fontSize: 12,
                        padding: 10,
                        boxSizing: 'border-box',
                      }}
                    >
                      <div
                        style={{
                          width: 220,
                          boxSizing: 'border-box',
                        }}
                      >
                        Categoria
                      </div>
                      <div
                        style={{
                          width: 40,
                          textAlign: 'center',
                          boxSizing: 'border-box',
                        }}
                      >
                        Lutas
                      </div>
                      <div
                        style={{
                          width: 40,
                          textAlign: 'center',
                          boxSizing: 'border-box',
                        }}
                      >
                        eta
                      </div>
                    </div>
                    {item.categories.map((item: any, indexCategory: number) => (
                      <StyledItemCategory
                        key={`${index}-${indexCategory}`}
                        style={{}}
                        onClick={() => handleClick(item)}
                      >
                        <div style={{ width: 220 }}>{formatName(item)}</div>
                        <div style={{ width: 40, textAlign: 'center' }}>
                          {item.numberFights}
                        </div>
                        <div style={{ width: 40, textAlign: 'center' }}>
                          {getFisrtFightDate(item.fights, item.numberFights)}
                        </div>
                      </StyledItemCategory>
                    ))}
                  </div>

                  <div>
                    <div>Horario inicial: {getFistDate(item.categories)}</div>
                    <div>Nº de categorias: {item.categories.length}</div>
                    <div>Nº de lutas: {countNumberFight(item.categories)}</div>
                    <div>Tempo total: {getTimeTotal(item.categories)}</div>
                    <div>Estimativa final: {getFinalEta(item.categories)}</div>
                  </div>
                </div>
              ))}
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

export default ListAreas;
