import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import AutorenewIcon from '@mui/icons-material/Autorenew';

import MonitorIcon from '@mui/icons-material/Monitor';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import RefreshIcon from '@mui/icons-material/Refresh';

import Replay5Icon from '@mui/icons-material/Replay5';
import Forward5Icon from '@mui/icons-material/Forward5';

import { Tooltip } from '@mui/material';
import { toast } from 'react-toastify';
import {
  StyledContentControl,
  StyledContentControlButton,
  StyledContentName,
  StyledContentNumber,
  StyledContentScore,
  StyledContentTime,
  StyledFightOne,
  StyledFightTwo,
  StyledImage,
  StyledLeft,
  StyledName,
  StyledRight,
  StyledRoot,
  StyledTeam,
} from './styles';
import useAuthenticate from '../../../recoil/hooks/authenticate';
import {
  changeOrderAthletes,
  finishFightRequest,
  getReasonVictoryRequest,
  getScoreboardDetails,
  resumeFightRequest,
  startedFightRequest,
  stoppedFightRequest,
} from '../../../api/scoreboard';
import ConfirmModal from '../../../components/ConfirmModal';
import ModalFinishFight from './ModalFinishFight';

const newLocal = 'Ir para a próxima luta';
const Scoreboard: React.FC = (): ReactElement => {
  const navigate = useNavigate();

  const { authenticate } = useAuthenticate();

  const { eventId, areaId, boardId } = useParams();

  const [time, setTime] = useState(0);
  const [defaultTime, setDefaultTime] = useState(0);
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const [startedFight, setStartedFight] = useState(false);

  const [openModalFinish, setOpenModalFinish] = useState(false);

  const [scoreDetails, setScoreDetails] = useState<any>(null);

  const [reasonVictory, setReasonVictory] = useState<any[]>([]);

  const [score, setScore] = useState({
    athleteOne: {
      id: '',
      points: 0,
      advantages: 0,
      faults: 0,
    },
    athleteTwo: {
      id: '',
      points: 0,
      advantages: 0,
      faults: 0,
    },
  });

  const [modalConfirm, setModalConfirm] = useState<{
    open: boolean;
    title: string;
    message: string;
    onConfirm: () => void;
    onCancel?: () => void;
  }>({
    open: false,
    title: 'Atenção',
    message: 'Deseja inverter a ordem dos atletas?',
    onConfirm: () => null,
  });

  const handleBackFight = async () => {
    try {
      if (authenticate.token && boardId) {
        await resumeFightRequest(authenticate.token, boardId);
        setOpenModalFinish(false);
      }
    } catch (erro) {
      // eslint-disable-next-line no-console
      console.log('erro', erro);
    }
  };

  const handleSubmitFinish = async (values: any) => {
    try {
      if (authenticate.token && boardId) {
        const reason = reasonVictory.find((item) => item.id === values.reason);

        const data = {
          ...values,
          reasonDescription: reason.description,
          timeFight: defaultTime - time,
        };

        await finishFightRequest(authenticate.token, boardId, data);

        const athleteWinner = scoreDetails?.athletes?.find(
          (item: any) => item.uuid === values.winner
        );

        if (athleteWinner) {
          localStorage.setItem(
            `@ilutas:${boardId}-winner`,
            JSON.stringify(athleteWinner)
          );
        } else {
          localStorage.setItem(`@ilutas:${boardId}-withoutWinner`, 'true');
        }

        toast('Luta finalizada com sucesso!', { type: 'success' });
        setOpenModalFinish(false);

        const otherFights = localStorage.getItem('allFights');

        if (otherFights) {
          const fights = JSON.parse(otherFights);

          const currentIndex = fights.indexOf(boardId);

          if (currentIndex !== -1 && currentIndex < fights.length - 1) {
            const nextId = fights[currentIndex + 1];

            setModalConfirm({
              open: true,
              message: newLocal,
              onConfirm: () => {
                setModalConfirm({ ...modalConfirm, open: false });
                navigate(
                  `/events/${eventId}/scoreboard/${areaId}/fight/${nextId}`
                );
                // eslint-disable-next-line dot-notation
                window.location.reload();
              },
              onCancel: () => {
                navigate(`/dash/events/${eventId}/scoreboard/${areaId}`);
              },
              title: 'Próxima luta',
            });
            return;
          }
        }

        navigate(`/dash/events/${eventId}/scoreboard/${areaId}`);
      }
    } catch (erro) {
      // eslint-disable-next-line no-console
      console.log('erro', erro);
    }
  };

  const handlePlayPause = () => {
    setStartedFight(true);
    setIsRunning((prevIsRunning) => !prevIsRunning);
  };

  const handleStopFight = async () => {
    setOpenModalFinish(true);

    if (time) {
      setIsRunning(false);
    }

    try {
      if (authenticate.token && boardId) {
        await stoppedFightRequest(authenticate.token, boardId);
      }
    } catch (erro) {
      // eslint-disable-next-line no-console
      console.log('erro', erro);
    }
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (isRunning && time > 0) {
      intervalId = setInterval(() => {
        setTime((prevTime) => {
          const newTime = prevTime - 1;
          localStorage.setItem(`@ilutas:${boardId}-time`, `${newTime}`);

          if (newTime === 0) {
            handleStopFight();

            clearInterval(intervalId);
          }
          return newTime;
        });
      }, 1000);
    }

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boardId, isRunning, time]);

  useEffect(() => {
    localStorage.setItem(`@ilutas:${boardId}-time`, `${time}`);
    localStorage.setItem(`@ilutas:board`, `${boardId}`);
    localStorage.setItem(
      `@ilutas:${boardId}-scoreDetails`,
      JSON.stringify(scoreDetails)
    );
    localStorage.setItem(`@ilutas:${boardId}-score`, JSON.stringify(score));
  }, [boardId, time, scoreDetails, score]);

  useEffect(() => {
    if (scoreDetails?.status === 'stopped') {
      setOpenModalFinish(true);
    }
  }, [scoreDetails]);

  useEffect(() => {
    return () => {
      localStorage.removeItem(`@ilutas:${boardId}-time`);
      localStorage.removeItem(`@ilutas:board`);
      localStorage.removeItem(`@ilutas:${boardId}-scoreDetails`);
      localStorage.removeItem(`@ilutas:${boardId}-score`);
      localStorage.removeItem(`@ilutas:${boardId}-winner`);
      localStorage.removeItem(`@ilutas:${boardId}-withoutWinner`);
    };
  }, [boardId]);

  const getAthlete = (athletes: any[], order: number): any => {
    return athletes?.find((item) => item.order === order);
  };

  const getData = useCallback(async () => {
    try {
      if (authenticate.token && boardId) {
        const data = await getScoreboardDetails(authenticate.token, boardId);

        if (
          data?.details?.status === 'completed' ||
          data?.details?.status === 'bye'
        ) {
          navigate(`/dash/events/${eventId}/scoreboard/${areaId}`);
          return;
        }

        setScoreDetails(data?.details || null);

        if (data?.details?.eventAreaCategory?.timeCategory) {
          const value = data?.details?.eventAreaCategory?.timeCategory * 60;
          setTime(value);
          setDefaultTime(value);
        }

        if (data?.details?.athletes?.length === 2) {
          const athleteOne = getAthlete(data.details.athletes, 0);
          const athleteTwo = getAthlete(data.details.athletes, 1);

          setScore({
            athleteOne: {
              ...score.athleteOne,
              id: athleteOne.uuid,
            },
            athleteTwo: {
              ...score.athleteTwo,
              id: athleteTwo.uuid,
            },
          });
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticate.token, boardId]);

  useEffect(() => {
    getData();
  }, [getData]);

  const getReasonVictory = useCallback(async () => {
    try {
      if (authenticate.token) {
        const data = await getReasonVictoryRequest(authenticate.token);

        setReasonVictory(data?.data || null);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticate.token]);

  useEffect(() => {
    getReasonVictory();
  }, [getReasonVictory]);

  const handleChangeOrderAthletes = async (): Promise<any> => {
    try {
      if (authenticate.token && boardId) {
        await changeOrderAthletes(authenticate.token, boardId);

        setModalConfirm({ ...modalConfirm, open: false });

        toast('Alteração realizada com sucesso!', { type: 'success' });

        getData();
      }
    } catch (erro) {
      toast(
        'Não foi possivel executar essa ação. Por favor, tente novamente!',
        { type: 'error' }
      );
    }
  };

  const handleOpenModalConfirmInvert = () => {
    setModalConfirm({
      ...modalConfirm,
      open: true,
      onConfirm: () => handleChangeOrderAthletes(),
    });
  };

  const handleResetTime = (): any => {
    localStorage.setItem(`@ilutas:${boardId}-time`, `${defaultTime}`);
    setTime(defaultTime);
    setIsRunning(false);
  };

  const handleStartFight = async () => {
    try {
      if (authenticate.token && boardId) {
        await startedFightRequest(authenticate.token, boardId);
        handlePlayPause();
      }
    } catch (erro) {
      toast(
        'Não foi possivel executar essa ação. Por favor, tente novamente!',
        { type: 'error' }
      );
    }
  };

  const handleResetTimeConfirm = () => {
    setModalConfirm({
      ...modalConfirm,
      message: 'Deseja resetar o tempo?',
      open: true,
      onConfirm: () => {
        handleResetTime();

        setModalConfirm({ ...modalConfirm, open: false });
      },
    });
  };

  const handleTime = (timeToAdd: number): void => {
    setTime((prevTime) => {
      const newTime = prevTime + timeToAdd;

      if (newTime > defaultTime) {
        localStorage.setItem(`@ilutas:${boardId}-time`, `${defaultTime}`);
        return defaultTime;
      }

      if (newTime < 0) {
        localStorage.setItem(`@ilutas:${boardId}-time`, `0`);
        return 0;
      }

      localStorage.setItem(`@ilutas:${boardId}-time`, `${newTime}`);
      return newTime;
    });
  };

  const mapStage = (stage: any): string => {
    const stageOrder: any = {
      'eighth-final': 'Oitavas de final',
      'quarter-final': 'Quartas de final',
      semifinal: 'Semifinal',
      bronze: 'Terceiro lugar',
      final: 'Final',
    };

    return stageOrder[stage] || stage;
  };

  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(
      remainingSeconds
    ).padStart(2, '0')}`;
  };

  const changePoints = (
    key: 'athleteOne' | 'athleteTwo',
    keyValue: 'points' | 'advantages' | 'faults',
    points: number
  ): void => {
    if (!startedFight) {
      return;
    }

    const newPoints = score[key][keyValue] + points;
    setScore({
      ...score,
      [key]: {
        ...score[key],
        [keyValue]: newPoints > 0 ? newPoints : 0,
      },
    });
  };

  const defaultWinnerByTime = useCallback(() => {
    if (time > 0) {
      return '';
    }
    const athleteOne = getAthlete(scoreDetails?.athletes, 0)?.uuid;
    const athleteTwo = getAthlete(scoreDetails?.athletes, 1)?.uuid;

    if (score.athleteOne.points > score.athleteTwo.points) {
      return athleteOne;
    }

    if (score.athleteTwo.points > score.athleteOne.points) {
      return athleteTwo;
    }

    const totalAthleteOne =
      score.athleteOne.advantages - score.athleteOne.faults > 0
        ? score.athleteOne.advantages - score.athleteOne.faults
        : 0;
    const totalAthleteTwo =
      score.athleteTwo.advantages - score.athleteTwo.faults > 0
        ? score.athleteTwo.advantages - score.athleteTwo.faults
        : 0;

    if (totalAthleteOne > totalAthleteTwo) {
      return athleteOne;
    }

    if (totalAthleteTwo > totalAthleteOne) {
      return athleteTwo;
    }

    if (score.athleteOne.advantages > score.athleteTwo.advantages) {
      return athleteOne;
    }

    if (score.athleteTwo.advantages > score.athleteOne.advantages) {
      return athleteTwo;
    }

    return '';
  }, [score, scoreDetails, time]);

  const formatName = (name: string): string => {
    if (!name) {
      return '';
    }

    return name;
  };

  return (
    <React.Fragment>
      <StyledRoot>
        <StyledFightOne>
          <StyledLeft>
            <StyledImage src={getAthlete(scoreDetails?.athletes, 0)?.photo} />
            <StyledContentName>
              <StyledName>
                {formatName(getAthlete(scoreDetails?.athletes, 0)?.nameAthlete)}
              </StyledName>
              <StyledTeam>
                {getAthlete(scoreDetails?.athletes, 0)?.gym}
              </StyledTeam>
            </StyledContentName>
          </StyledLeft>
          <StyledRight>
            <StyledContentScore>
              <StyledContentNumber color="#4caf50">
                {score.athleteOne.points}
              </StyledContentNumber>

              <React.Fragment>
                <StyledContentControl>
                  <StyledContentControlButton
                    onClick={() => changePoints('athleteOne', 'points', -2)}
                  >
                    <RemoveIcon />
                  </StyledContentControlButton>
                  <div>2</div>
                  <StyledContentControlButton
                    onClick={() => changePoints('athleteOne', 'points', 2)}
                  >
                    <AddIcon />
                  </StyledContentControlButton>
                </StyledContentControl>
                <StyledContentControl>
                  <StyledContentControlButton
                    onClick={() => changePoints('athleteOne', 'points', -3)}
                  >
                    <RemoveIcon />
                  </StyledContentControlButton>
                  <div>3</div>
                  <StyledContentControlButton
                    onClick={() => changePoints('athleteOne', 'points', 3)}
                  >
                    <AddIcon />
                  </StyledContentControlButton>
                </StyledContentControl>
                <StyledContentControl>
                  <StyledContentControlButton
                    onClick={() => changePoints('athleteOne', 'points', -4)}
                  >
                    <RemoveIcon />
                  </StyledContentControlButton>
                  <div>4</div>
                  <StyledContentControlButton
                    onClick={() => changePoints('athleteOne', 'points', 4)}
                  >
                    <AddIcon />
                  </StyledContentControlButton>
                </StyledContentControl>
              </React.Fragment>
            </StyledContentScore>

            <StyledContentScore>
              <StyledContentNumber color="#f1c40f">
                {score.athleteOne.advantages}
              </StyledContentNumber>

              <StyledContentControl>
                <StyledContentControlButton
                  onClick={() => changePoints('athleteOne', 'advantages', -1)}
                >
                  <RemoveIcon />
                </StyledContentControlButton>
                <StyledContentControlButton
                  onClick={() => changePoints('athleteOne', 'advantages', 1)}
                >
                  <AddIcon />
                </StyledContentControlButton>
              </StyledContentControl>
            </StyledContentScore>

            <StyledContentScore>
              <StyledContentNumber color="#b71c1c">
                {score.athleteOne.faults}
              </StyledContentNumber>

              <StyledContentControl>
                <StyledContentControlButton
                  onClick={() => changePoints('athleteOne', 'faults', -1)}
                >
                  <RemoveIcon />
                </StyledContentControlButton>
                <StyledContentControlButton
                  onClick={() => changePoints('athleteOne', 'faults', 1)}
                >
                  <AddIcon />
                </StyledContentControlButton>
              </StyledContentControl>
            </StyledContentScore>
          </StyledRight>
        </StyledFightOne>

        <StyledFightTwo>
          <StyledLeft>
            <StyledImage src={getAthlete(scoreDetails?.athletes, 1)?.photo} />
            <StyledContentName>
              <StyledName>
                {formatName(getAthlete(scoreDetails?.athletes, 1)?.nameAthlete)}
              </StyledName>
              <StyledTeam>
                {getAthlete(scoreDetails?.athletes, 1)?.gym}
              </StyledTeam>
            </StyledContentName>
          </StyledLeft>
          <StyledRight>
            <StyledContentScore>
              <StyledContentNumber color="#4caf50">
                {score.athleteTwo.points}
              </StyledContentNumber>

              <React.Fragment>
                <StyledContentControl>
                  <StyledContentControlButton
                    onClick={() => changePoints('athleteTwo', 'points', -2)}
                  >
                    <RemoveIcon />
                  </StyledContentControlButton>
                  <div>2</div>
                  <StyledContentControlButton
                    onClick={() => changePoints('athleteTwo', 'points', 2)}
                  >
                    <AddIcon />
                  </StyledContentControlButton>
                </StyledContentControl>
                <StyledContentControl>
                  <StyledContentControlButton
                    onClick={() => changePoints('athleteTwo', 'points', -3)}
                  >
                    <RemoveIcon />
                  </StyledContentControlButton>
                  <div>3</div>
                  <StyledContentControlButton
                    onClick={() => changePoints('athleteTwo', 'points', 3)}
                  >
                    <AddIcon />
                  </StyledContentControlButton>
                </StyledContentControl>
                <StyledContentControl>
                  <StyledContentControlButton
                    onClick={() => changePoints('athleteTwo', 'points', -4)}
                  >
                    <RemoveIcon />
                  </StyledContentControlButton>
                  <div>4</div>
                  <StyledContentControlButton
                    onClick={() => changePoints('athleteTwo', 'points', 4)}
                  >
                    <AddIcon />
                  </StyledContentControlButton>
                </StyledContentControl>
              </React.Fragment>
            </StyledContentScore>

            <StyledContentScore>
              <StyledContentNumber color="#f1c40f">
                {score.athleteTwo.advantages}
              </StyledContentNumber>

              <StyledContentControl>
                <StyledContentControlButton
                  onClick={() => changePoints('athleteTwo', 'advantages', -1)}
                >
                  <RemoveIcon />
                </StyledContentControlButton>
                <StyledContentControlButton
                  onClick={() => changePoints('athleteTwo', 'advantages', 1)}
                >
                  <AddIcon />
                </StyledContentControlButton>
              </StyledContentControl>
            </StyledContentScore>

            <StyledContentScore>
              <StyledContentNumber color="#b71c1c">
                {score.athleteTwo.faults}
              </StyledContentNumber>

              <StyledContentControl>
                <StyledContentControlButton
                  onClick={() => changePoints('athleteTwo', 'faults', -1)}
                >
                  <RemoveIcon />
                </StyledContentControlButton>
                <StyledContentControlButton
                  onClick={() => changePoints('athleteTwo', 'faults', 1)}
                >
                  <AddIcon />
                </StyledContentControlButton>
              </StyledContentControl>
            </StyledContentScore>
          </StyledRight>
        </StyledFightTwo>

        <StyledContentTime>
          <div
            style={{
              flex: 1,
              padding: 12,
              display: 'flex',
              flexDirection: 'column',
              gap: 24,
              width: '50%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                fontSize: 120,
                fontWeight: 600,
                color: '#FFF',
              }}
            >
              {formatTime(time)}
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'space-between',
                width: '100%',
              }}
            >
              <div style={{ display: 'flex', gap: 12 }}>
                {!startedFight && (
                  <Tooltip title="Inverter ordem dos atletas" arrow>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: '#fff',
                        border: '1px solid #ccc',
                        borderRadius: 8,
                        padding: '4px 12px',
                        boxSizing: 'border-box',
                        gap: 16,
                        minWidth: 50,
                        cursor: 'pointer',
                      }}
                      onClick={handleOpenModalConfirmInvert}
                    >
                      <AutorenewIcon fontSize="large" />
                    </div>
                  </Tooltip>
                )}

                <Tooltip title="Espelhar placar em uma nova janela" arrow>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      background: '#fff',
                      border: '1px solid #ccc',
                      borderRadius: 8,
                      padding: '4px 12px',
                      boxSizing: 'border-box',
                      gap: 16,
                      minWidth: 50,
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      window.open(
                        `${window.location.origin}/#/scoreboard/live`,
                        '_blank'
                      );
                    }}
                  >
                    <MonitorIcon fontSize="large" />
                  </div>
                </Tooltip>
              </div>

              {startedFight ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: '#fff',
                    border: '1px solid #ccc',
                    borderRadius: 8,
                    padding: '4px 12px',
                    boxSizing: 'border-box',
                    gap: 16,
                    minWidth: 300,
                    cursor: 'pointer',
                  }}
                >
                  <Tooltip title="Voltar 5 segundos" arrow>
                    <Replay5Icon
                      fontSize="large"
                      onClick={() => handleTime(5)}
                    />
                  </Tooltip>
                  {isRunning ? (
                    <Tooltip title="Pausar tempo" arrow>
                      <PauseIcon fontSize="large" onClick={handlePlayPause} />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Retomar tempo" arrow>
                      <PlayArrowIcon
                        fontSize="large"
                        onClick={handlePlayPause}
                      />
                    </Tooltip>
                  )}
                  <Tooltip title="Avançar 5 segundos" arrow>
                    <Forward5Icon
                      fontSize="large"
                      onClick={() => handleTime(-5)}
                    />
                  </Tooltip>
                  <Tooltip title="Resetar tempo" arrow>
                    <RefreshIcon
                      fontSize="large"
                      onClick={() => handleResetTimeConfirm()}
                    />
                  </Tooltip>
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: '#fff',
                    border: '1px solid #ccc',
                    borderRadius: 8,
                    padding: '4px 12px',
                    boxSizing: 'border-box',
                    gap: 16,
                    minWidth: 200,
                    cursor: 'pointer',
                  }}
                  onClick={handleStartFight}
                >
                  {scoreDetails?.status === 'in_progress'
                    ? 'Retomar luta'
                    : 'Iniciar luta'}
                </div>
              )}

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: '#fff',
                  border: '1px solid #ccc',
                  borderRadius: 8,
                  padding: '4px 12px',
                  boxSizing: 'border-box',
                  cursor: 'pointer',
                }}
                onClick={handleStopFight}
              >
                Concluir luta
              </div>
            </div>
          </div>
          <div
            style={{
              width: '50%',
              flex: 1,
              color: '#FFF',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: 24,
            }}
          >
            <div style={{ fontSize: 48 }}>
              {mapStage(scoreDetails?.stage || '')}
            </div>
            <div
              style={{
                fontSize: 36,
                width: '100%',
                maxWidth: '100%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {scoreDetails?.eventAreaCategory?.categoryName || ''}
            </div>
          </div>
        </StyledContentTime>
      </StyledRoot>

      <ModalFinishFight
        open={openModalFinish}
        handleBack={handleBackFight}
        // handleClose={() => setOpenModalFinish(false)}
        reasonVictory={reasonVictory}
        handleSubmit={handleSubmitFinish}
        getDefaultWinnerByTime={defaultWinnerByTime}
        athletes={scoreDetails?.athletes?.sort(
          (a: any, b: any) => a.order - b.order
        )}
      />

      <ConfirmModal
        open={modalConfirm.open}
        handleClose={() => {
          setModalConfirm((prev) => {
            return {
              ...prev,
              open: false,
            };
          });
        }}
        handleCancel={modalConfirm.onCancel}
        handleConfirm={modalConfirm.onConfirm}
        title={modalConfirm.title}
        message={modalConfirm.message}
        confirmColor="#07bc0c"
      />
    </React.Fragment>
  );
};

export default Scoreboard;
