import * as yup from 'yup';

export const initialLoginFormValues = {
  phone: '',
};

export interface LoginFormProps {
  phone: string;
}

export const validationSchema = yup.object({
  phone: yup.string().required('Campo obrigatório'),
});
