import * as yup from 'yup';

export const initialVerifyTokenFormValues = {
  token: '',
};

export interface VerifyTokenFormProps {
  token: string;
}

export const validationSchema = yup.object({
  token: yup
    .string()
    .min(5, 'Token deve ter 5 números')
    .required('Campo obrigatório'),
});
