import { Box } from '@mui/material';
import styled from 'styled-components';

export const StyledItemCategory = styled(Box)({
  width: 300,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px 10px',
  boxSizing: 'border-box',
  transition: '0.4s',
  cursor: 'pointer',

  '&:hover': {
    background: '#083061aa',
    color: '#fff',
  },

  '&:active': {
    background: '#083061',
    color: '#fff',
  },
});

export const StyledItemArea = styled('div')({
  border: '1px solid #ccc',
  borderRadius: 8,
  width: 200,
  minHeight: 100,
  height: 'auto',
  marginTop: 12,
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  transition: '0.4s',
  cursor: 'pointer',
  '&:hover': {
    transform: 'scale(1.04)',
    background: '#f1f1f1',
  },
});
