import { TextField } from '@mui/material';
import styled from 'styled-components';

export const StyledRoot = styled('div')({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: 50,
});

export const StyledContent = styled('div')({
  display: 'flex',
  gap: 100,
});

export const StyledLogo = styled('img')({
  width: 350,
  maxWidth: '90%',
});

export const StyledForm = styled('form')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: 24,
  width: 400,
});

export const StyledContentImage = styled('div')({
  width: 400,
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
});

export const StyledImage = styled('img')({
  width: 320,
});

export const StyledTitle = styled('h2')({
  fontSize: 36,
  textAlign: 'center',
  margin: 0,
});

export const StyledContentSubtitle = styled('div')({
  // background: 'red',
  height: 20,
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
});

export const StyledLine = styled('div')({
  background: '#777',
  height: 2,
  width: '100%',
});

export const StyledLineText = styled('div')({
  position: 'absolute',
  background: '#fff',
  padding: '0 24px',
  fontSize: 16,
});

export const StyledTextField = styled(TextField)({
  // background: 'red',
});
