import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import ScoreboardIcon from '@mui/icons-material/Scoreboard';
import MenuIcon from '@mui/icons-material/Menu';
import EventIcon from '@mui/icons-material/Event';

import { IconButton } from '@mui/material';
import {
  StyledChangeEvent,
  StyledChangeEventButton,
  StyledContainer,
  StyledDrawer,
  StyledHeader,
  StyledHeaderButtonDrawer,
  StyledOutlet,
  StyledRoot,
} from './styles';
import DrawerMenu from '../../../components/DrawerMenu';
import useWindowWidth from '../../../hooks/useWindowWidth';
import useAuthenticate from '../../../recoil/hooks/authenticate';
import { getEvent } from '../../../api/events';
import useEvent from '../../../recoil/hooks/event';
// import { fromBase64WithObfuscation } from '../../../utils/hash';

const Dashboard: React.FC = (): ReactElement => {
  const { authenticate } = useAuthenticate();
  const navigate = useNavigate();

  const isStaff = useMemo(() => {
    return authenticate?.user?.role === 'S';
  }, [authenticate?.user?.role]);

  const { eventId } = useParams();

  const { event, setEvent } = useEvent();

  const [openDrawer, setOpenDrawe] = useState(false);

  const getData = useCallback(async () => {
    try {
      if (authenticate.token && eventId) {
        // const event = fromBase64WithObfuscation(eventId);
        const data = await getEvent(authenticate.token, eventId);

        setEvent(data?.event);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    }
  }, [authenticate.token, eventId]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleChangeDrawer = () => {
    setOpenDrawe((previewValue) => !previewValue);
  };

  const width = useWindowWidth();

  const drawerMenuItems = useMemo(() => {
    const items = [];

    if (isStaff) {
      items.push({
        label: 'Evento',
        icon: <EventIcon />,
        path: 'home',
      });
      items.push({
        label: 'Áreas',
        icon: <SpaceDashboardIcon />,
        path: 'areas',
      });
    }

    items.push({
      label: 'Placar',
      icon: <ScoreboardIcon />,
      path: 'scoreboard',
    });

    return items;
  }, [isStaff]);

  return (
    <StyledRoot>
      <StyledDrawer>
        <DrawerMenu
          open={openDrawer}
          onClose={() => setOpenDrawe(false)}
          variant={width > 900 ? 'permanent' : 'temporary'}
          options={drawerMenuItems}
        />
      </StyledDrawer>
      <StyledContainer>
        <StyledHeader>
          <StyledHeaderButtonDrawer>
            {width < 900 && (
              <IconButton onClick={handleChangeDrawer}>
                <MenuIcon />
              </IconButton>
            )}
            Bem vindo, {authenticate?.user?.name || 'amigo'}
          </StyledHeaderButtonDrawer>
          <StyledChangeEvent>
            {event?.name}
            <StyledChangeEventButton onClick={() => navigate('/dash/events')}>
              Alterar
            </StyledChangeEventButton>
          </StyledChangeEvent>
        </StyledHeader>
        <StyledOutlet>
          <Outlet />
        </StyledOutlet>
      </StyledContainer>
    </StyledRoot>
  );
};

export default Dashboard;
