import React, { ReactElement } from 'react';

import { CircularProgress, Typography } from '@mui/material';
import { StyledRoot } from './styles';

export interface DetailsSectionRowsProps {
  label: string;
  key: string;
  subkey?: string;
  isHtml?: boolean;
  transform?: (text: any) => string;
  transformBody?: (data: any) => string;
  transformIcon?: (text: any) => ReactElement;
}

export interface DetailsSectionProps {
  title?: string;
  rows: DetailsSectionRowsProps[];
  data: any;
}

interface Props {
  isLoading: boolean;
  noPadding?: boolean;
  sections: DetailsSectionProps[];
}

const DetailsInfo: React.FC<Props> = ({
  isLoading,
  sections,
  noPadding,
}): React.ReactElement => {
  const handleValue = (
    data: any,
    key: DetailsSectionRowsProps
  ): string | ReactElement => {
    const value = key.subkey ? data?.[key.key]?.[key.subkey] : data?.[key.key];
    if (value === undefined || value === null || value === '') {
      return '-';
    }

    if (key.isHtml) {
      return (
        <div
          style={{ padding: 5, boxSizing: 'border-box' }}
          dangerouslySetInnerHTML={{ __html: value.replaceAll('\n', '<br/>') }}
        />
      );
    }

    if (key.transformBody) {
      return key.transformBody(data);
    }

    if (key.transformIcon) {
      return key.transformIcon(value);
    }

    if (key.transform) {
      return key.transform(value);
    }

    return value;
  };
  return (
    <StyledRoot nopadding={!!noPadding}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
        {sections.map((item, index) => (
          <div
            key={index}
            style={{ display: 'flex', flexDirection: 'column', gap: 4 }}
          >
            {item.title && <Typography variant="h6">{item.title}</Typography>}

            {isLoading && (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: 32,
                  height: 150,
                  boxSizing: 'border-box',
                }}
              >
                <CircularProgress />
              </div>
            )}

            {!isLoading &&
              !!item.data &&
              item.rows?.map((row, indexRow) => (
                <div key={`${index}-${indexRow}`} style={{ color: '#555' }}>
                  {row.label}:{' '}
                  <span style={{ color: '#000' }}>
                    {handleValue(item.data, row)}
                  </span>
                </div>
              ))}
          </div>
        ))}
      </div>
    </StyledRoot>
  );
};

export default DetailsInfo;
