import styled from 'styled-components';

export const StyledRoot = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 32px;
  box-sizing: border-box;
`;
