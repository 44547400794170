/* eslint-disable no-plusplus */
import React, { ReactElement } from 'react';

import { Button } from '@mui/material';

interface NoAreasProps {
  title?: string;
  message?: string;
  handleStart?: () => void;
}

const NoAreas: React.FC<NoAreasProps> = ({
  title = 'Organizar áreas',
  message = 'Configure agora mesmo as áreas do evento!',
  handleStart,
}): ReactElement => {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        padding: '0px 24px 24px 24px',
        boxSizing: 'border-box',
      }}
    >
      <div style={{ textAlign: 'center', fontSize: 18 }}>{title}</div>

      <div
        style={{
          flexDirection: 'column',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 24,
          gap: 24,
          boxSizing: 'border-box',

          border: '1px solid #ccc',
          borderRadius: 16,
          marginTop: 24,
        }}
      >
        {message}
        {handleStart && (
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={handleStart}
            style={{
              letterSpacing: 1,
              fontSize: 14,
            }}
          >
            Começar
          </Button>
        )}
      </div>
    </div>
  );
};

export default NoAreas;
