import { Box } from '@mui/material';
import styled from 'styled-components';

export const StyledItemCategory = styled(Box)({
  // padding: 12,
  width: 300,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px 10px',
  boxSizing: 'border-box',
  transition: '0.4s',
  cursor: 'pointer',
  '&:hover': {
    background: '#ddd',
  },

  // '&:active': {
  //   background: '#dd0',
  // },
});
