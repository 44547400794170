import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { StyledRoot } from './styles';
import useAuthenticate from '../../../recoil/hooks/authenticate';

import {
  getEventAreasFights,
  getEventCategories,
  getEventFilters,
  saveEventAreas,
} from '../../../api/events';

import Card from '../../../components/Card';
import DetailsInfo from '../../../components/DetailsInfo';

import ModalInvalid from './ModalInvalid';
import OrganizeAreas from '../../../components/OrganizeAreas';
import NoAreas from '../../../components/NoAreas';
import ShowAreas from '../../../components/ShowAreas';
import ConfirmModal from '../../../components/ConfirmModal';
import LoadingModal from './LoadingModal';

const AreasOrganize: React.FC = (): ReactElement => {
  const { authenticate } = useAuthenticate();
  const navigate = useNavigate();

  const { eventId } = useParams();

  const [isEditing, setEditing] = useState(false);

  const [modalConfirm, setModalConfirm] = useState<{
    open: boolean;
    title: string;
    message: string;
    onConfirm: () => void;
    onCancel?: () => void;
  }>({
    open: false,
    title: 'Atenção',
    message:
      'O evento já começou, ao editar as áreas os dados do placar podem sofrer alterações. Deseja continuar?',
    onConfirm: () => {
      setEditing(true);
      setModalConfirm((prev) => ({
        ...prev,
        open: false,
      }));
    },
  });

  const [event, setEvent] = useState<any>(null);
  const [categories, setCategories] = useState<any>([]);

  const [eventAreas, setEventAreas] = useState<any>([]);

  const [isLoadingSave, setLoadingSave] = useState(false);

  const [filters, setFilters] = useState<any>({});

  const [isModalInvalid, setModalInvalid] = useState(false);

  const [isLoading, setLoading] = useState(true);
  const [isLoadingAreas, setLoadingAreas] = useState(true);
  const [isLoadingFilters, setLoadingFilters] = useState(true);

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      if (authenticate.token && eventId) {
        const data = await getEventCategories(authenticate.token, eventId);

        setEvent(data?.event || null);
        setCategories(data?.categories || []);

        // const requiredFieldsEvent = ['numberAreas', 'startTimeFight', '']

        if (!data?.event?.numberAreas) {
          setModalInvalid(true);
        }
      }
    } catch (error) {
      toast(
        'Não foi possivel executar essa ação. Por favor, tente novamente!',
        { type: 'error' }
      );
    } finally {
      setLoading(false);
    }
  }, [authenticate.token, eventId]);

  useEffect(() => {
    getData();
  }, [getData]);

  const getEventAreasFightsData = useCallback(
    async (defaultLoading = true) => {
      try {
        setLoadingAreas(defaultLoading);
        if (authenticate.token && eventId) {
          const data = await getEventAreasFights(authenticate.token, eventId);

          setEventAreas(data?.areas || []);
        }
      } catch (error) {
        toast(
          'Não foi possivel executar essa ação. Por favor, tente novamente!',
          { type: 'error' }
        );
      } finally {
        setLoadingAreas(false);
      }
    },
    [authenticate.token, eventId]
  );

  useEffect(() => {
    getEventAreasFightsData();
  }, [getEventAreasFightsData]);

  const getFilters = useCallback(async () => {
    try {
      setLoadingFilters(true);
      if (authenticate.token && eventId) {
        const data = await getEventFilters(authenticate.token, eventId);
        setFilters(data?.filters || {});
      }
    } catch (error) {
      toast(
        'Não foi possivel executar essa ação. Por favor, tente novamente!',
        { type: 'error' }
      );
    } finally {
      setLoadingFilters(false);
    }
  }, [authenticate.token, eventId]);

  const handleSave = async (data: any, generateFights: boolean) => {
    setLoadingSave(true);
    try {
      if (authenticate.token && eventId) {
        await saveEventAreas(
          authenticate.token,
          eventId,
          generateFights,
          data.map((item: any, index: number) => {
            return {
              ...item,
              categories: item.categories.map(
                (category: any, indexCategory: number) => {
                  return {
                    ...category,
                    order: indexCategory,
                  };
                }
              ),
              order: index,
            };
          })
        );

        toast('Lutas geradas com sucesso!', { type: 'success' });

        await getEventAreasFightsData(false);
      }
    } catch (error) {
      toast(
        'Não foi possivel executar essa ação. Por favor, tente novamente!',
        { type: 'error' }
      );
    } finally {
      // setLoading(false);
      setLoadingSave(false);
    }
  };

  useEffect(() => {
    getFilters();
  }, [getFilters]);

  const isLoadingGeneral = useMemo(() => {
    return isLoading || isLoadingAreas || isLoadingFilters;
  }, [isLoading, isLoadingAreas, isLoadingFilters]);

  const handleStartEditing = () => {
    const hasStarted = eventAreas?.some(
      (item: any) => item.status !== 'pending'
    );

    if (hasStarted) {
      setModalConfirm({ ...modalConfirm, open: true });
    } else {
      setEditing(true);
    }
  };

  return (
    <React.Fragment>
      <ModalInvalid
        open={isModalInvalid}
        cause={[]}
        handleClose={() => navigate(`/dash/events/${eventId}/home?edit=true`)}
        message="Alguns campos do evento não estão configurados. Por favor, finaliza a configuração primeiro!"
      />

      <LoadingModal
        open={isLoadingSave}
        message="Aguarde enquanto estamos gerando as lutas"
        title="Gerando lutas"
      />
      <StyledRoot>
        <Card>
          <div style={{ width: '100%' }}>
            <DetailsInfo
              isLoading={isLoading}
              sections={[
                {
                  rows: [
                    {
                      key: 'name',
                      label: 'Nome',
                    },
                    {
                      key: 'numberDaysFight',
                      label: 'Número de dias',
                    },
                    {
                      key: 'numberAreas',
                      label: 'Número de áreas',
                    },
                  ],
                  data: event,
                  title: 'Evento',
                },
              ]}
            />

            {!isLoadingGeneral &&
              !!event &&
              !eventAreas?.length &&
              !isEditing && <NoAreas handleStart={() => setEditing(true)} />}

            {!isLoadingGeneral &&
              !!event &&
              !isEditing &&
              !!eventAreas?.length && (
                <ShowAreas
                  categories={categories}
                  eventAreas={eventAreas}
                  filtersData={filters}
                  areas={event?.numberAreas}
                  numberDays={event?.numberDaysFight}
                  startTime={event.startTimeFight}
                  handleStart={handleStartEditing}
                />
              )}

            {!isLoadingGeneral && !!event && isEditing && (
              <OrganizeAreas
                categories={categories}
                handleFinish={() => {
                  setEditing(false);
                  getData();
                  getEventAreasFightsData();
                }}
                eventAreas={eventAreas}
                filtersData={filters}
                areas={event?.numberAreas}
                numberDays={event?.numberDaysFight}
                startTime={event.startTimeFight}
                handleSave={handleSave}
                isLoading={isLoadingSave}
              />
            )}
          </div>
        </Card>
      </StyledRoot>
      <ConfirmModal
        open={modalConfirm.open}
        handleClose={() => {
          setModalConfirm((prev) => {
            return {
              ...prev,
              open: false,
            };
          });
        }}
        handleCancel={modalConfirm.onCancel}
        handleConfirm={modalConfirm.onConfirm}
        title={modalConfirm.title}
        message={modalConfirm.message}
        confirmColor="#333"
      />
    </React.Fragment>
  );
};

export default AreasOrganize;
