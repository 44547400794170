import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import {
  StyleContentCard,
  StyledContainer,
  StyledHeader,
  StyledOutlet,
  StyledRoot,
} from './styles';
import { getPublicEvents } from '../../../api/events';
import Card from '../../../components/Card';

const Events: React.FC = (): ReactElement => {
  const navigate = useNavigate();

  const [events, setEvents] = useState([]);

  const getData = useCallback(async () => {
    try {
      const data = await getPublicEvents();

      setEvents(data?.events || []);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    }
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleSubmit = (keyEvent: string) => {
    navigate(`/events/${keyEvent}`);
  };

  return (
    <StyledRoot>
      <StyledContainer>
        <StyledHeader>
          <Button onClick={() => navigate('/login')}>Entrar</Button>
        </StyledHeader>
        <StyledOutlet>
          <StyleContentCard>
            <div style={{ padding: '32px 0px', fontSize: 32 }}>
              <Typography component="h1" fontSize={32}>
                Eventos
              </Typography>
            </div>
            {events.map((item: any, index) => (
              <Card key={index} onClick={() => handleSubmit(item.keyEvent)}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 16,
                    gap: 8,
                  }}
                >
                  <Typography component="h2" fontSize={20}>
                    {item.name}
                  </Typography>
                  <div>Dia: {item.startDate}</div>
                  <div>Hora: {item.startTimeFight}</div>
                  <div>Número de áreas: {item.numberAreas}</div>
                </div>
              </Card>
            ))}
          </StyleContentCard>
        </StyledOutlet>
      </StyledContainer>
    </StyledRoot>
  );
};

export default Events;
