import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

interface EventDefault {
  name: string;
  image: string;
}

const eventAtom = atom({
  key: '@ilutas:eventAtom',
  default: {
    name: '',
    image: '',
  } as EventDefault,
  effects_UNSTABLE: [persistAtom],
});

export default eventAtom;
