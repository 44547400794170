/* eslint-disable no-param-reassign */
export const maskPhone = (telefone: string): string => {
  if (!telefone) return '';
  telefone = telefone.replace(/\D/g, '');
  telefone = telefone.replace(/(\d{2})(\d)/, '($1) $2');
  telefone = telefone.replace(/(\d)(\d{4})$/, '$1-$2');
  return telefone.substring(0, 16);
};

export const formatPhoneNumberMask = (phoneNumber: string): string => {
  // Remove todos os caracteres não numéricos
  const cleaned = phoneNumber.replace(/\D/g, '');

  // Limita o comprimento máximo a 11 caracteres
  const limited = cleaned.slice(0, 11);

  // Formata o número de acordo com o comprimento
  if (limited.length <= 2) {
    return limited; // (XX
  }
  if (limited.length <= 6) {
    return `(${limited.slice(0, 2)}) ${limited.slice(2)}`; // (XX) XXXX
  }
  if (limited.length <= 10) {
    return `(${limited.slice(0, 2)}) ${limited.slice(2, 6)}-${limited.slice(
      6,
      10
    )}`; // (XX) XXXX-XXXX
  }
  return `(${limited.slice(0, 2)}) ${limited.slice(2, 7)}-${limited.slice(
    7,
    11
  )}`; // (XX) XXXXX-XXXX
};
