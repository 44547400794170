import api from '../services/api';

export const getEvents = async (token: string): Promise<any> => {
  const result = await api.get('/api/events', {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });

  return result?.data;
};

export const selectEvent = async (
  token: string,
  eventId: string
): Promise<any> => {
  const result = await api.post(
    '/api/select-event',
    { eventId },
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    }
  );

  return result?.data;
};

export const getPublicEvents = async (): Promise<any> => {
  const result = await api.get('/api/public/events', {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return result?.data;
};

export const getPublicEventAreas = async (eventId: string): Promise<any> => {
  const result = await api.get(`/api/public/events/${eventId}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return result?.data;
};

export const getPublicBracket = async (
  eventId: string,
  bracketId: string
): Promise<any> => {
  const result = await api.get(
    `/api/public/events/${eventId}/brackets/${bracketId}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  return result?.data;
};

export interface EditEventRequest {
  startDate: string;
  endDate: string;
  name: string;
  startTimeFight: string;
  numberDaysFight: string;
  timeBetweenFight: string;
  timeBetweenCategory: string;
  timeCategoryOneAthlete: string;
  numberAreas: string;
  numberThird: string;
  typeKey3: string;
}

export const editEvent = async (
  event: string,
  data: EditEventRequest,
  token: string
): Promise<void> => {
  await api.put(`/api/events/${event}`, data, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });
};

export const getEvent = async (
  token: string,
  eventId: string
): Promise<any> => {
  const result = await api.get(`/api/events/${eventId}`, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });

  return result?.data;
};

export const getEventCategories = async (
  token: string,
  eventId: string
): Promise<any> => {
  const result = await api.get(`/api/events/${eventId}/categories`, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });

  return result?.data;
};

export const getEventAreas = async (
  token: string,
  eventId: string
): Promise<any> => {
  const result = await api.get(`/api/events/${eventId}/areas`, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });

  return result?.data;
};

export const getEventAreasFights = async (
  token: string,
  eventId: string
): Promise<any> => {
  const result = await api.get(`/api/events/${eventId}/areas-fights`, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });

  return result?.data;
};

export const getEventAreaFights = async (
  token: string,
  eventId: string,
  areaId: string
): Promise<any> => {
  const result = await api.get(
    `/api/events/${eventId}/areas-fights/${areaId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    }
  );

  return result?.data;
};

export const getEventFilters = async (
  token: string,
  eventId: string
): Promise<any> => {
  const result = await api.get(`/api/events/${eventId}/filters`, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });

  return result?.data;
};

export const saveEventAreas = async (
  token: string,
  eventId: string,
  generateFights: boolean,
  data: any
): Promise<any> => {
  await api.post(
    `/api/v2/events/${eventId}/areas`,
    { data, generateFights },
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    }
  );
};

interface AddEventRequest {
  name: string;
  location: string;
  date: Date;
  photo: string;
}

export const addEvent = async (
  data: AddEventRequest,
  token: string
): Promise<void> => {
  await api.post('/api/events', data, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });
};

export default {
  getEvent,
  editEvent,
  getEventCategories,

  getEvents,
  addEvent,
};
