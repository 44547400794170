/* eslint-disable no-plusplus */
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { MenuItem, Select } from '@mui/material';
import { StyledItemCategory } from '../styles';
import { formatOnlyHour } from '../../../utils/date';

interface AreasCategoryProps {
  categories: any[];
  startTime: string;
  eventAreas?: any[];
  areas: number;
  numberDays: number;
  filters: any;
  daySelected: string;
}

const AreasCategory: React.FC<AreasCategoryProps> = ({
  categories,
  eventAreas,
  areas,
  filters,
  numberDays,
  daySelected,
}): ReactElement => {
  const [areasObject, setAreasObject] = useState<any[]>([]);

  const [days, setDays] = useState<number[]>([]);

  useEffect(() => {
    if (areas > 0 && eventAreas?.length && categories?.length) {
      let newItems = [...categories];
      const newDays: number[] = [];
      const newAreas = eventAreas.map((item) => {
        newDays.push(item.day);
        return {
          id: `${item.day}-${item.order}`,
          day: item.day,
          name: item.name,
          categories: item.categories?.length
            ? item.categories
                .map((category: any) => {
                  const categoryData = categories.find((itemCategory) => {
                    return itemCategory.categoryId === category.category;
                  });

                  newItems = newItems.filter(
                    (item) => item.categoryId !== category.category
                  );

                  return {
                    ...categoryData,
                    fights: category.fights,
                    order: category.order,
                  };
                })
                .sort((a: any, b: any) => {
                  return a.order - b.order;
                })
            : [],
        };
      });

      setDays([...new Set(newDays)].sort());

      setAreasObject(newAreas);
      // setItems(newItems);
    } else {
      if (areas > 0) {
        const value = [];
        const newDays: number[] = [];

        for (let day = 0; day < numberDays; day++) {
          newDays.push(day);
          for (let i = 0; i < areas; i++) {
            value.push({
              id: `${day}-${i}`,
              day,
              name: `Área ${i + 1}`,
              categories: [],
            });
          }
        }

        setDays(newDays);
        setAreasObject(value);
      }
      if (categories?.length) {
        // setItems(categories);
      }
    }
  }, [areas, eventAreas, categories, numberDays]);

  const applyFilters = useCallback(
    (items: any) => {
      if (
        filters.category ||
        filters.gender ||
        filters.track ||
        filters.weight ||
        filters.typeCategory
      ) {
        const itemsFiltered = items.filter((item: any) => {
          let avaliable = true;
          if (filters.category && item.categoryName !== filters.category) {
            avaliable = false;
          }
          if (filters.gender && item.genderName !== filters.gender) {
            avaliable = false;
          }
          if (filters.weight && item.weightName !== filters.weight) {
            avaliable = false;
          }
          if (filters.track && item.trackName !== filters.track) {
            avaliable = false;
          }
          if (
            filters.typeCategory &&
            item.typeCategoryName !== filters.typeCategory
          ) {
            avaliable = false;
          }
          return avaliable;
        });

        return itemsFiltered;
      }

      return items;
    },
    [filters]
  );

  const formatName = (item: any): string => {
    return item.descriptionCategory;
  };

  const countNumberFight = (categories: any[]): number => {
    return categories.reduce(
      (prev, current) => {
        return Number(prev) + Number(current.numberFights);
      },
      [0]
    );
  };

  const filterByDay = (items: any[], day: number): any[] => {
    return items.filter((item) => item.day === day);
  };

  const sortArrayV2 = (array: any[]): any[] => {
    if (!array?.length) {
      return [];
    }
    return array.sort((a, b) => {
      const timeA = new Date(a.date);
      const timeB = new Date(b.date);
      return timeA.getTime() - timeB.getTime();
    });
  };

  const getFistDate = (categories: any[]): string => {
    if (!categories.length) {
      return '0 min';
    }
    const firstCategory = categories.find((item) => item.order === 0);

    if (!firstCategory) {
      return '';
    }

    const fisrtFightsSorted = sortArrayV2(firstCategory.fights);

    const firstFight = fisrtFightsSorted[0];

    if (!firstFight) {
      return '';
    }

    return formatOnlyHour(firstFight.date);
  };

  const getFinalEta = (categories: any[]): string => {
    if (!categories.length) {
      return 'xx:xx';
    }
    const lastCategory = categories.find(
      (item) => item.order === categories.length - 1
    );

    if (!lastCategory) {
      return '';
    }

    const fightsSorted = sortArrayV2(lastCategory.fights);

    if (!fightsSorted.length) {
      return '';
    }

    return formatOnlyHour(fightsSorted[fightsSorted.length - 1].date);
  };

  const differenceInMinutesCeil = (date1: Date, date2: Date): number => {
    const differenceInMilliseconds = Math.abs(
      new Date(date2).getTime() - new Date(date1).getTime()
    );

    const differenceInMinutes = Math.ceil(
      differenceInMilliseconds / (1000 * 60)
    );

    return differenceInMinutes;
  };

  const formatMinutesToTime = (minutes: number): string => {
    if (minutes < 60) {
      return `${minutes} min`;
    }
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    if (remainingMinutes === 0) {
      return `${hours} ${hours === 1 ? 'hora' : 'horas'}`;
    }
    return `${hours} ${
      hours === 1 ? 'hora' : 'horas'
    } e ${remainingMinutes} min`;
  };

  const getTimeTotal = (categories: any[]): string => {
    if (!categories.length) {
      return '0 min';
    }
    const firstCategory = categories.find((item) => item.order === 0);
    const lastCategory = categories.find(
      (item) => item.order === categories.length - 1
    );

    if (!firstCategory || !lastCategory) {
      return '';
    }

    const fisrtFightsSorted = sortArrayV2(firstCategory.fights);
    const lastFightsSorted = sortArrayV2(lastCategory.fights);

    const firstFight = fisrtFightsSorted[0];
    const lastFight = lastFightsSorted[lastFightsSorted.length - 1];

    if (!firstFight || !lastFight) {
      return '';
    }

    const diff = differenceInMinutesCeil(lastFight.date, firstFight.date);

    return `${formatMinutesToTime(diff)}`;
  };

  const getFisrtFightDate = (fights: any[], numberFights: number): string => {
    if (!numberFights || !fights.length) {
      return 'xx:xx';
    }
    const fightsSorted = sortArrayV2(fights);
    return formatOnlyHour(fightsSorted[0].date);
  };

  const renderObjectDay = useMemo(() => {
    if (typeof daySelected !== 'number') {
      return [days[0]];
    }

    return days.filter((item) => Number(item) === Number(daySelected));
  }, [days, daySelected]);

  return (
    <React.Fragment>
      <div
        style={{
          flexDirection: 'row',
          display: 'flex',
          gap: 12,
        }}
      >
        <div style={{ width: '100%' }}>
          {renderObjectDay.map((day) => (
            <React.Fragment key={day}>
              <div
                style={{
                  width: '100%',
                  overflowX: 'auto',
                  display: 'flex',
                  gap: 18,
                }}
              >
                {filterByDay(areasObject, day).map((item, index) => (
                  <div key={index} style={{ minWidth: 300, width: 300 }}>
                    <div>{item.name}</div>
                    <div
                      style={{
                        border: '1px solid #ccc',
                        borderRadius: 8,
                        width: 300,
                        minHeight: 100,
                        height: 'auto',
                        marginTop: 12,
                        boxSizing: 'border-box',
                        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          width: 300,
                          fontSize: 12,
                          padding: 10,
                          boxSizing: 'border-box',
                        }}
                      >
                        <div
                          style={{
                            width: 220,
                            // background: 'yellow',
                            boxSizing: 'border-box',
                          }}
                        >
                          Categoria
                        </div>
                        <div
                          style={{
                            width: 40,
                            textAlign: 'center',
                            boxSizing: 'border-box',
                          }}
                        >
                          Lutas
                        </div>
                        <div
                          style={{
                            width: 40,
                            textAlign: 'center',
                            boxSizing: 'border-box',
                          }}
                        >
                          eta
                        </div>
                      </div>
                      {applyFilters(item.categories).map(
                        (item: any, indexCategory: number) => (
                          <StyledItemCategory
                            key={`${index}-${indexCategory}`}
                            style={{}}
                            onClick={() => null}
                          >
                            <div style={{ width: 220 }}>{formatName(item)}</div>
                            <div style={{ width: 40, textAlign: 'center' }}>
                              {item.numberFights}
                            </div>
                            <div style={{ width: 40, textAlign: 'center' }}>
                              {getFisrtFightDate(
                                item.fights,
                                item.numberFights
                              )}
                            </div>
                          </StyledItemCategory>
                        )
                      )}
                    </div>
                    <div>
                      <div>Horario inicial: {getFistDate(item.categories)}</div>
                      <div>Nº de categorias: {item.categories.length}</div>
                      <div>
                        Nº de lutas: {countNumberFight(item.categories)}
                      </div>
                      <div>Tempo total: {getTimeTotal(item.categories)}</div>
                      <div>
                        Estimativa final: {getFinalEta(item.categories)}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default AreasCategory;
