export const formatDate = (date: Date | undefined): string => {
  if (!date) {
    return '-';
  }

  const options: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    month: 'long',
    day: 'numeric',
    weekday: 'long',
  };

  return new Intl.DateTimeFormat('pt-BR', options).format(new Date(date));
};

export const formatShortDate = (date: Date | undefined): string => {
  if (!date) {
    return '-';
  }

  const options: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };

  return new Intl.DateTimeFormat('pt-BR', options)
    .format(new Date(date))
    .replace(',', '');
};

export const formatOnlyDate = (date: Date | undefined): string => {
  if (!date) {
    return '-';
  }

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };

  return new Intl.DateTimeFormat('pt-BR', options).format(new Date(date));
};

export const formatOnlyDateWithDayOfWeek = (date: Date | undefined): string => {
  if (!date) {
    return '-';
  }

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    weekday: 'short',
  };

  return new Intl.DateTimeFormat('pt-BR', options)
    .format(new Date(date))
    .toUpperCase()
    .replace('.,', ',');
};

export const formatOnlyHour = (
  date: Date | undefined,
  seconds = false
): string => {
  if (!date) {
    return '-';
  }

  const options: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    second: seconds ? 'numeric' : undefined,
  };

  return new Intl.DateTimeFormat('pt-BR', options).format(new Date(date));
};

export const calculateDiffDatesInSeconds = (d1: string, d2: string): number => {
  const date1 = new Date(d1);
  const date2 = new Date(d2);

  const diff = (date2.getTime() - date1.getTime()) / 1000;

  return Math.abs(Math.round(diff));
};

export const formatSeconds = (time: number | string): string => {
  const timeNumber = Number(time);
  const hours = Math.floor(timeNumber / 3600);
  const minutes = Math.floor((timeNumber % 3600) / 60);

  const displayHours = hours ? `${hours}h` : '0h';
  const displayMinutes = minutes ? `${minutes}m` : '0m';

  return `${displayHours}:${displayMinutes}`;
};

export const formatDateWithoutHours = (date: string): string => {
  return date.split('-').reverse().join('/');
};

export const formatTime = (timeString: string): string => {
  const [hours, minutes] = timeString.split(':');

  const hour = Number(hours);
  const minute = Number(minutes);

  const formattedHour = hour < 10 ? `0${hour}` : hour;
  const formattedMinutes = minute < 10 ? `0${minute}` : minute;

  return `${formattedHour}:${formattedMinutes}`;
};
