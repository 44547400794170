/* eslint-disable no-plusplus */
import React, { ReactElement } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import AreasFights from './AreasFights';

interface ShowScoreboardAreaDetailsProps {
  categories: any[];
  eventArea?: any;
  areas: number;
  numberDays: number;
}

const ShowScoreboardAreaDetails: React.FC<ShowScoreboardAreaDetailsProps> = ({
  categories,
  eventArea,
  areas,
  numberDays,
}): ReactElement => {
  const navigate = useNavigate();

  const { eventId, areaId } = useParams();

  const handleRedirectFight = (fight: any) => {
    navigate(`/events/${eventId}/scoreboard/${areaId}/fight/${fight.uuid}`);
  };

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        padding: '0px 24px 24px 24px',
        boxSizing: 'border-box',
      }}
    >
      <AreasFights
        areas={areas}
        categories={categories}
        numberDays={numberDays}
        eventArea={eventArea}
        redirect={handleRedirectFight}
      />
    </div>
  );
};

export default ShowScoreboardAreaDetails;
