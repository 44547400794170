import api from '../services/api';

export const getScoreboardDetails = async (
  token: string,
  id?: string
): Promise<any> => {
  const result = await api.get(`/api/scoreboard/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });

  return result?.data;
};

export const startedFightRequest = async (
  token: string,
  id: string
): Promise<any> => {
  const result = await api.post(
    `/api/scoreboard/${id}/start`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    }
  );

  return result?.data;
};

export const stoppedFightRequest = async (
  token: string,
  id: string
): Promise<any> => {
  const result = await api.post(
    `/api/scoreboard/${id}/stop`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    }
  );

  return result?.data;
};

export const finishFightRequest = async (
  token: string,
  id: string,
  body: any
): Promise<any> => {
  const result = await api.post(`/api/scoreboard/${id}/finish`, body, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });

  return result?.data;
};

export const resumeFightRequest = async (
  token: string,
  id: string
): Promise<any> => {
  const result = await api.post(
    `/api/scoreboard/${id}/resume`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    }
  );

  return result?.data;
};

export const changeOrderAthletes = async (
  token: string,
  id: string
): Promise<any> => {
  const result = await api.post(
    `/api/scoreboard/${id}/change-order`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    }
  );

  return result?.data;
};

export const getReasonVictoryRequest = async (token: string): Promise<any> => {
  const result = await api.get(
    `/api/reason-victory`,

    {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    }
  );

  return result?.data;
};
