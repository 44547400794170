import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { StyledRoot } from './styles';
import useAuthenticate from '../../../recoil/hooks/authenticate';

import { getEventAreasFights, getEventCategories } from '../../../api/events';

import Card from '../../../components/Card';
import DetailsInfo from '../../../components/DetailsInfo';

import ModalInvalid from './ModalInvalid';
import NoAreas from '../../../components/NoAreas';
import ShowScoreboardAreas from '../../../components/ShowScoreboardAreas';

const ScoreboardAreas: React.FC = (): ReactElement => {
  const { authenticate } = useAuthenticate();
  const navigate = useNavigate();

  const { eventId } = useParams();

  const [event, setEvent] = useState<any>(null);
  const [categories, setCategories] = useState<any>([]);

  const [eventAreas, setEventAreas] = useState<any>([]);

  const [isModalInvalid, setModalInvalid] = useState(false);

  const [isLoading, setLoading] = useState(true);
  const [isLoadingAreas, setLoadingAreas] = useState(true);

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      if (authenticate.token && eventId) {
        // const event = fromBase64WithObfuscation(eventId);
        const data = await getEventCategories(authenticate.token, eventId);

        setEvent(data?.event || null);
        setCategories(data?.categories || []);

        // const requiredFieldsEvent = ['numberAreas', 'startTimeFight', '']

        if (!data?.event?.numberAreas) {
          setModalInvalid(true);
        }
      }
    } catch (error) {
      toast(
        'Não foi possivel executar essa ação. Por favor, tente novamente!',
        { type: 'error' }
      );
    } finally {
      setLoading(false);
    }
  }, [authenticate.token, eventId]);

  useEffect(() => {
    getData();
  }, [getData]);

  const getEventAreasFightsData = useCallback(async () => {
    try {
      setLoadingAreas(true);
      if (authenticate.token && eventId) {
        const data = await getEventAreasFights(authenticate.token, eventId);

        setEventAreas(data?.areas || []);
      }
    } catch (error) {
      toast(
        'Não foi possivel executar essa ação. Por favor, tente novamente!',
        { type: 'error' }
      );
    } finally {
      setLoadingAreas(false);
    }
  }, [authenticate.token, eventId]);

  useEffect(() => {
    getEventAreasFightsData();
  }, [getEventAreasFightsData]);

  const isLoadingGeneral = useMemo(
    () => isLoading || isLoadingAreas,
    [isLoading, isLoadingAreas]
  );

  return (
    <StyledRoot>
      <ModalInvalid
        open={isModalInvalid}
        cause={[]}
        handleClose={() => navigate(`/dash/events/${eventId}/home?edit=true`)}
        message="Alguns campos do evento não estão configurados. Por favor, finaliza a configuração primeiro!"
      />

      <Card>
        <div style={{ width: '100%' }}>
          <DetailsInfo
            isLoading={isLoadingGeneral}
            sections={[
              {
                rows: [
                  {
                    key: 'name',
                    label: 'Nome',
                  },
                  {
                    key: 'numberDaysFight',
                    label: 'Número de dias',
                  },
                  {
                    key: 'numberAreas',
                    label: 'Número de áreas',
                  },
                ],
                data: event,
                title: 'Evento',
              },
            ]}
          />

          {!isLoadingGeneral && !!event && !eventAreas?.length && (
            <NoAreas
              title="Visualizar áreas"
              message="Nenhuma área configurada para o evento"
            />
          )}

          {!isLoadingGeneral && !!event && !!eventAreas?.length && (
            <ShowScoreboardAreas
              categories={categories}
              eventAreas={eventAreas}
              areas={event?.numberAreas}
              numberDays={event?.numberDaysFight}
              startTime={event.startTimeFight}
            />
          )}
        </div>
      </Card>
    </StyledRoot>
  );
};

export default ScoreboardAreas;
