import styled from 'styled-components';
import { Typography } from '@mui/material';

export const StyledRoot = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  flexDirection: 'column',

  padding: 24,
  gap: 24,
  boxSizing: 'border-box',
});

export const StyledForm = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  gap: 40,
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '100%',
});

export const StyledContentInputs = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  width: '100%',
});

export const StyledContentButtons = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: 16,
  width: '100%',
});

export const StyledContentTitle = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  padding: '10px 0px 20px 0px',
});

export const StyledTitle = styled(Typography)({});
