/* eslint-disable no-plusplus */
import React, { ReactElement, useMemo } from 'react';

import { Autocomplete, Button, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { initialFilterFormValues } from './schema';

interface FilterOrganizeAreaProps {
  filtersData?: {
    weight: string[];
    track: string[];
    category: string[];
  };
  categories: any[];
  hasFiltersApplied: boolean;
  handleSubmit: (values: any) => void;
}

const FilterOrganizeArea: React.FC<FilterOrganizeAreaProps> = ({
  categories,
  filtersData,
  hasFiltersApplied,
  handleSubmit,
}): ReactElement => {
  const data = useMemo(() => {
    const uniqueCategories = filtersData?.category || [];
    const uniqueTack = filtersData?.track || [];
    const uniqueWeight = filtersData?.weight || [];

    const allGender = categories
      .map((item: any) => item.genderName)
      .filter((item: any) => !!item);
    const uniqueGender = [...new Set(allGender)];

    const allTypes = categories
      .map((item: any) => item.typeCategoryName)
      .filter((item: any) => !!item);
    const uniqueTypes = [...new Set(allTypes)];

    return {
      uniqueCategories,
      uniqueTack,
      uniqueWeight,
      uniqueGender,
      uniqueTypes,
    };
  }, [categories, filtersData]);

  const onSubmit = (values: any) => {
    handleSubmit(values);
  };

  const formik = useFormik({
    initialValues: initialFilterFormValues,
    enableReinitialize: true,
    onSubmit,
  });

  const resetForm = () => {
    formik.setValues(initialFilterFormValues);
    handleSubmit(initialFilterFormValues);
  };

  const handleChangeCategory = (_event: any, value: any) => {
    formik.setFieldValue('category', value?.value || '');
  };

  const handleChangeTrack = (_event: any, value: any) => {
    formik.setFieldValue('track', value?.value || '');
  };

  const handleChangeType = (_event: any, value: any) => {
    formik.setFieldValue('typeCategory', value?.value || '');
  };

  const handleChangeWeight = (_event: any, value: any) => {
    formik.setFieldValue('weight', value?.value || '');
  };

  const handleChangeGender = (_event: any, value: any) => {
    formik.setFieldValue('gender', value?.value || '');
  };

  return (
    <form
      onSubmit={formik.handleSubmit}
      style={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        padding: 16,
        gap: 16,
        boxSizing: 'border-box',
      }}
    >
      <div
        style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'row',
          gap: 16,
          boxSizing: 'border-box',
        }}
      >
        <Autocomplete
          options={data.uniqueTypes?.map((typeCategory) => ({
            label: typeCategory,
            value: typeCategory,
          }))}
          inputValue={formik.values.typeCategory}
          isOptionEqualToValue={(item) => {
            return item.value === formik.values.typeCategory;
          }}
          getOptionLabel={(item) => {
            return item.label;
          }}
          fullWidth
          onChange={handleChangeType}
          onBlur={formik.handleBlur}
          noOptionsText="Nenhuma opção encontrada"
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              id="typeCategory"
              variant="outlined"
              name="track"
              label="Tipo da categoria"
              value={formik.values.typeCategory}
              InputLabelProps={{
                style: {
                  fontSize: 16,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
            />
          )}
        />

        <Autocomplete
          options={data.uniqueCategories?.map((category) => ({
            label: category,
            value: category,
          }))}
          inputValue={formik.values.category}
          isOptionEqualToValue={(item) => {
            return item.value === formik.values.category;
          }}
          getOptionLabel={(item) => {
            return item.label;
          }}
          fullWidth
          onChange={handleChangeCategory}
          onBlur={formik.handleBlur}
          noOptionsText="Nenhuma opção encontrada"
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              id="category"
              variant="outlined"
              name="category"
              label="Categoria"
              value={formik.values.category}
              InputLabelProps={{
                style: {
                  fontSize: 16,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
            />
          )}
        />

        <Autocomplete
          options={data.uniqueTack?.map((track) => ({
            label: track,
            value: track,
          }))}
          inputValue={formik.values.track}
          isOptionEqualToValue={(item) => {
            return item.value === formik.values.track;
          }}
          getOptionLabel={(item) => {
            return item.label;
          }}
          fullWidth
          onChange={handleChangeTrack}
          onBlur={formik.handleBlur}
          noOptionsText="Nenhuma opção encontrada"
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              id="track"
              variant="outlined"
              name="track"
              label="Graduação"
              value={formik.values.track}
              InputLabelProps={{
                style: {
                  fontSize: 16,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
            />
          )}
        />

        <Autocomplete
          options={data.uniqueWeight?.map((weight) => ({
            label: weight,
            value: weight,
          }))}
          inputValue={formik.values.weight}
          isOptionEqualToValue={(item) => {
            return item.value === formik.values.weight;
          }}
          getOptionLabel={(item) => {
            return item.label;
          }}
          fullWidth
          onChange={handleChangeWeight}
          onBlur={formik.handleBlur}
          noOptionsText="Nenhuma opção encontrada"
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              id="weight"
              variant="outlined"
              name="weight"
              label="Peso"
              value={formik.values.weight}
              InputLabelProps={{
                style: {
                  fontSize: 16,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
            />
          )}
        />

        <Autocomplete
          options={data.uniqueGender?.map((gender) => ({
            label: gender,
            value: gender,
          }))}
          inputValue={formik.values.gender}
          isOptionEqualToValue={(item) => {
            return item.value === formik.values.gender;
          }}
          getOptionLabel={(item) => {
            return item.label;
          }}
          fullWidth
          onChange={handleChangeGender}
          onBlur={formik.handleBlur}
          noOptionsText="Nenhuma opção encontrada"
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              id="gender"
              variant="outlined"
              name="gender"
              label="Sexo"
              value={formik.values.gender}
              InputLabelProps={{
                style: {
                  fontSize: 16,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
            />
          )}
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 16 }}>
        {hasFiltersApplied && (
          <Button
            color="error"
            style={{
              letterSpacing: 1,
              fontSize: 14,
            }}
            onClick={() => resetForm()}
          >
            Limpar filtros
          </Button>
        )}
        <Button
          color="primary"
          variant="contained"
          type="submit"
          style={{
            letterSpacing: 1,
            fontSize: 14,
          }}
        >
          Filtrar
        </Button>
      </div>
    </form>
  );
};

export default FilterOrganizeArea;
