import { useRecoilState } from 'recoil';

import authenticateAtom from '../atoms/authenticate';

const decodeToken = (
  token: string
): { id: string; name: string; phone: string; entity: number } => {
  const tokenSplited = token.split('.');
  const payload = tokenSplited[1];
  const payloadClean = payload.replace('-', '+').replace('_', '/');
  const payloadDecoded = decodeURIComponent(escape(atob(payloadClean)));
  const result = JSON.parse(payloadDecoded);

  return result;
};

const useAuthenticate = (): any => {
  const [authenticate, setAuthenticate] = useRecoilState(authenticateAtom);

  const signin = (token: string) => {
    const decode = decodeToken(token);
    setAuthenticate({
      isAuthenticate: true,
      token,
      user: decode,
    });
  };

  const logout = () => {
    setAuthenticate({
      isAuthenticate: false,
      token: '',
      user: null,
    });
  };

  return { authenticate, setAuthenticate, signin, decodeToken, logout };
};

export default useAuthenticate;
