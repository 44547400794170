/* eslint-disable no-plusplus */
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Avatar,
  Badge,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Tooltip,
} from '@mui/material';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RedoIcon from '@mui/icons-material/Redo';

import { toast } from 'react-toastify';
import { StyledItem } from './styles';
import { formatOnlyHour } from '../../../utils/date';
import ModalFinishFight from '../../../pages/private/Scoreboard/ModalFinishFight';
import useAuthenticate from '../../../recoil/hooks/authenticate';
import {
  finishFightRequest,
  getReasonVictoryRequest,
} from '../../../api/scoreboard';

interface AreasFightsProps {
  categories: any[];
  eventArea?: any;
  areas: number;
  numberDays: number;
  redirect: (item: any) => void;
}

const AreasFights: React.FC<AreasFightsProps> = ({
  categories,
  eventArea,
  areas,
  redirect,
  numberDays,
}): ReactElement => {
  const { authenticate } = useAuthenticate();

  const [areasObject, setAreasObject] = useState<any>(null);

  const [showCompleteds, setShowCompleteds] = useState(false);

  const [reasonVictory, setReasonVictory] = useState<any[]>([]);

  const [modalFinish, setModalFinish] = useState({
    open: false,
    athletes: [],
    fightId: '',
    defaultWinner: '',
  });

  const getReasonVictory = useCallback(async () => {
    try {
      if (authenticate.token) {
        const data = await getReasonVictoryRequest(authenticate.token);

        setReasonVictory(data?.data || null);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticate.token]);

  useEffect(() => {
    getReasonVictory();
  }, [getReasonVictory]);

  const handleOpenModalFinish = (fight: any, order: number): void => {
    const athlete = fight?.athletes?.[order];

    setModalFinish({
      open: true,
      athletes: fight.athletes || [],
      defaultWinner: athlete?.uuid || '',
      fightId: fight.uuid,
    });
  };

  useEffect(() => {
    if (areas > 0 && eventArea && categories?.length) {
      const fights: any[] = [];

      eventArea.categories.forEach((category: any) => {
        category.fights.forEach((fight: any) => {
          fights.push({
            ...fight,
            athletes: fight.athletes?.sort(
              (a: any, b: any) => a.order - b.order
            ),
            category,
          });
        });
      });

      setAreasObject({
        id: `${eventArea.day}-${eventArea.order}`,
        day: eventArea.day,
        name: eventArea.name,
        categories: [],
        fights,
      });
    }
  }, [areas, eventArea, categories, numberDays]);

  const sortArrayV2 = (array: any[]): any[] => {
    if (!array?.length) {
      return [];
    }
    return array.sort((a, b) => {
      const timeA = new Date(a.date);
      const timeB = new Date(b.date);
      return timeA.getTime() - timeB.getTime();
    });
  };

  const mapStage = (stage: any): string => {
    const stageOrder: any = {
      'eighth-final': 'Oitavas de final',
      'quarter-final': 'Quartas de final',
      semifinal: 'Semifinal',
      bronze: 'Disputa de Terceiro',
      final: 'Final',
    };

    return stageOrder[stage] || stage;
  };

  const mapStatus = (
    status: any
  ): { label: string; color: string; background: string } => {
    const options: any = {
      pending: { label: 'Pendente', color: '#fff', background: '#404243' },
      completed: { label: 'Finalizada', color: '#000', background: '#66bb6b' },
      canceled: { label: 'Cancelada', color: '#fff', background: '#f44337' },
      programed: { label: 'Programada', color: '#000', background: '#90caf9' },
      stopped: {
        label: 'Aguardando resultado',
        color: '#000',
        background: '#ffa727',
      },
      in_progress: {
        label: 'Em andamento',
        color: '#000',
        background: '#29b6f6',
      },

      bye: {
        label: 'BYE',
        color: '#000',
        background: '#8bc34a',
      },
    };

    return options[status] || {};
  };

  const handleFilterFights = useMemo(() => {
    if (showCompleteds) {
      return sortArrayV2(areasObject?.fights);
    }
    const items = areasObject?.fights?.filter((item: any) => {
      return !['completed', 'bye'].includes(item.status);
    });

    const result = sortArrayV2(items);

    localStorage.setItem(
      'allFights',
      JSON.stringify(result.map((item) => item.uuid))
    );

    return result;
  }, [areasObject?.fights, showCompleteds]);

  const firstFightToScoreboard = useMemo(() => {
    return handleFilterFights.find(
      (item) => !['completed', 'bye'].includes(item.status)
    );
  }, [handleFilterFights]);

  const isWinner = (athlete: any, result: any): boolean => {
    return result && athlete?.id === result?.eventAreaCategoryFightAthleteId;
  };

  const handleRedirect = (fight: any) => {
    if (fight.status === 'completed') {
      return undefined;
    }

    if (fight.uuid !== firstFightToScoreboard?.uuid) {
      return undefined;
    }

    return () => redirect(fight);
  };

  const handleSubmitFinish = async (values: any) => {
    try {
      if (authenticate.token && modalFinish.fightId) {
        const reason = reasonVictory.find((item) => item.id === values.reason);

        const data = {
          ...values,
          reasonDescription: reason.description,
          timeFight: 0,
        };

        await finishFightRequest(authenticate.token, modalFinish.fightId, data);

        toast('Atleta avançado com sucesso!', { type: 'success' });
        setModalFinish({ ...modalFinish, open: false });

        window.location.reload();
      }
    } catch (erro) {
      // eslint-disable-next-line no-console
      console.log('erro', erro);
    }
  };

  return (
    <React.Fragment>
      <div
        style={{
          flexDirection: 'row',
          display: 'flex',
          gap: 12,
        }}
      >
        <div style={{ width: '100%' }}>
          <div style={{ width: '100%' }}>
            <div style={{ textAlign: 'left', fontSize: 30, paddingBottom: 12 }}>
              Dia {areasObject?.day + 1} {'>'}{' '}
              <span style={{ textDecoration: 'underline' }}>
                {' '}
                {areasObject?.name}
              </span>
            </div>

            <div>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showCompleteds}
                      onChange={(e) => setShowCompleteds(e.target.checked)}
                    />
                  }
                  label="Mostrar lutas finalizadas"
                />
              </FormGroup>
            </div>
            {!handleFilterFights.length && !!areasObject?.fights && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  flex: 1,
                  padding: 24,
                  gap: 24,
                  border: '1px solid #ccc',
                  borderRadius: 8,
                  marginTop: 12,
                }}
              >
                <div>Todas as lutas foram finalizadas.</div>
                <Button
                  variant="contained"
                  onClick={() => setShowCompleteds(true)}
                >
                  Ver lutas finalizadas
                </Button>
              </div>
            )}

            <div
              style={{
                borderRadius: 8,
                width: '100%',
                minHeight: 100,
                height: 'auto',
                marginTop: 12,
                boxSizing: 'border-box',
                gap: 8,
                display: handleFilterFights?.length ? 'flex' : 'none',
                flexDirection: 'column',
                maxWidth: 450,
              }}
            >
              {handleFilterFights?.map((fight: any) => (
                <StyledItem
                  key={fight.id}
                  status={fight.status}
                  onClick={handleRedirect(fight)}
                >
                  {handleRedirect(fight) && (
                    <div
                      style={{
                        padding: '8px 8px 24px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Button variant="contained">Iniciar placar</Button>
                    </div>
                  )}
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 8,
                        }}
                      >
                        <div
                          style={{
                            width: 12,
                            height: 12,
                            background: 'green',
                            borderRadius: 12,
                          }}
                        />
                        <div style={{ fontWeight: 'bold' }}>
                          {fight.status === 'bye'
                            ? 'XX:XX'
                            : formatOnlyHour(fight.date)}
                        </div>
                        <div>
                          {mapStage(fight.stage)}{' '}
                          {!['final', 'bronze'].includes(fight.stage)
                            ? fight.order + 1
                            : ''}
                        </div>
                      </div>
                      <div
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {fight.category?.categoryName}
                      </div>
                    </div>
                    <div
                      style={{
                        border: '1px solid #ccc',
                        padding: '4px 8px',
                        boxSizing: 'border-box',
                        borderRadius: 8,
                        fontSize: 12,
                        background: mapStatus(fight?.status)?.background,
                        color: mapStatus(fight?.status)?.color,
                      }}
                    >
                      {mapStatus(fight?.status)?.label}
                    </div>
                  </div>

                  <div
                    style={{
                      margin: '4px 0px',
                      borderRadius: 4,
                      overflow: 'hidden',
                    }}
                  >
                    <div
                      style={{
                        background: '#2B4755',
                        color: '#fff',
                        padding: 8,
                        boxSizing: 'border-box',
                        height: 50,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: 8,
                      }}
                    >
                      <Badge
                        overlap="circular"
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        badgeContent={
                          isWinner(fight.athletes?.[0], fight.result) ? (
                            <CheckCircleIcon
                              style={{ width: 16, height: 16 }}
                              htmlColor="lightgreen"
                            />
                          ) : undefined
                        }
                      >
                        <Avatar
                          alt={fight.athletes?.[0]?.nameAthlete}
                          src={fight.athletes?.[0]?.photo}
                          sx={{ width: 30, height: 30 }}
                          style={{
                            border: isWinner(fight.athletes?.[0], fight.result)
                              ? '2px solid lightgreen'
                              : '2px solid #000',
                          }}
                        />
                      </Badge>
                      <div style={{ flex: 1 }}>
                        <div
                          style={{
                            fontSize: 14,
                            width: 220,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {fight.athletes?.[0]?.nameAthlete || '---'}
                        </div>
                        <div
                          style={{
                            fontSize: 10,
                            paddingTop: 4,
                            width: 220,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {fight.athletes?.[0]?.gym || '---'}
                        </div>
                      </div>

                      {!!fight.athletes?.[0] &&
                        !['bye', 'completed'].includes(fight.status) && (
                          <Tooltip title="Avançar atleta" arrow placement="top">
                            <div
                              style={{
                                background: '#fff',
                                padding: 0,
                                width: 30,
                                height: 30,
                                borderRadius: 50,
                                cursor: 'pointer',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleOpenModalFinish(fight, 0);
                              }}
                            >
                              <RedoIcon htmlColor="#2B4755" />
                            </div>
                          </Tooltip>
                        )}
                    </div>

                    <div
                      style={{
                        background: '#ddd',
                        color: '#000',
                        padding: 8,
                        boxSizing: 'border-box',
                        height: 50,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: 8,
                      }}
                    >
                      <Badge
                        overlap="circular"
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        badgeContent={
                          isWinner(fight.athletes?.[1], fight.result) ? (
                            <CheckCircleIcon
                              style={{ width: 16, height: 16 }}
                              htmlColor="lightgreen"
                            />
                          ) : undefined
                        }
                      >
                        <Avatar
                          alt={fight.athletes?.[1]?.nameAthlete}
                          src={fight.athletes?.[1]?.photo}
                          sx={{ width: 30, height: 30 }}
                          style={{
                            border: isWinner(fight.athletes?.[1], fight.result)
                              ? '2px solid lightgreen'
                              : '2px solid #000',
                          }}
                        />
                      </Badge>

                      <div style={{ flex: 1 }}>
                        <div
                          style={{
                            fontSize: 14,
                            width: 220,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {fight.athletes?.[1]?.nameAthlete || '---'}
                        </div>
                        <div
                          style={{
                            fontSize: 10,
                            paddingTop: 4,
                            width: 220,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {fight.athletes?.[1]?.gym || '---'}
                        </div>
                      </div>

                      {!!fight.athletes?.[1] &&
                        !['bye', 'completed'].includes(fight.status) && (
                          <Tooltip title="Avançar atleta" arrow placement="top">
                            <div
                              style={{
                                background: '#fff',
                                padding: 0,
                                width: 30,
                                height: 30,
                                borderRadius: 50,
                                cursor: 'pointer',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleOpenModalFinish(fight, 1);
                              }}
                            >
                              <RedoIcon htmlColor="#2B4755" />
                            </div>
                          </Tooltip>
                        )}
                    </div>
                  </div>
                </StyledItem>
              ))}
            </div>
          </div>
        </div>
      </div>

      <ModalFinishFight
        open={modalFinish.open}
        title="Avançar atleta"
        defaultWinner={modalFinish.defaultWinner}
        handleBack={() => setModalFinish({ ...modalFinish, open: false })}
        reasonVictory={reasonVictory}
        backButtonText="Cancelar"
        hiddenWithoutWinner
        handleSubmit={handleSubmitFinish}
        athletes={modalFinish?.athletes?.sort(
          (a: any, b: any) => a.order - b.order
        )}
      />
    </React.Fragment>
  );
};

export default AreasFights;
