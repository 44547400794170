import api from '../services/api';

interface Data {
  phone: string;
}

export const signinRequest = async (data: Data): Promise<any> => {
  const result = await api.post(
    '/api/signin',
    {
      phone: data.phone,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  return result?.data;
};

export const sendToken = async (data: Data): Promise<any> => {
  const result = await api.post(
    '/api/send-token',
    {
      phone: data.phone,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  return result?.data;
};

interface VerifyTokenData {
  phone: string;
  token: string;
}

export const verifyToken = async (data: VerifyTokenData): Promise<any> => {
  const result = await api.post(
    '/api/verify-token',
    {
      phone: data.phone,
      token: data.token,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  return result?.data;
};

export default {
  signinRequest,
  verifyToken,
  sendToken,
};
