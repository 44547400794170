import styled from 'styled-components';

export const StyledRoot = styled.span`
  width: 100%;
  min-height: 100vh;
  display: flex;
`;

export const StyledDrawer = styled('div')({
  maxWidth: 275,
  minHeight: '100vh',
  '@media (min-width: 900px) ': {
    width: 275,
  },
});

export const StyledHeader = styled('div')({
  background: '#fff',
  width: '100%',
  height: '75px',

  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 20,
  padding: 32,
  boxSizing: 'border-box',
  fontWeight: 'bold',
  fontSize: 20,
});

export const StyledHeaderButtonDrawer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 20,
  boxSizing: 'border-box',
});

export const StyledContainer = styled('div')({
  background: '#dadfde',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  '@media (min-width: 900px) ': {
    width: 'calc(100% - 275px)',
  },
});

export const StyledOutlet = styled('div')({
  padding: '16px',
  boxSizing: 'border-box',
});

export const StyledChangeEvent = styled('div')({
  display: 'flex',
  gap: 8,
  fontSize: 16,
  justifyContent: 'center',
  alignItems: 'center',
});

export const StyledChangeEventButton = styled('div')({
  fontSize: 16,
  border: '1px solid #ccc',
  padding: 10,
  borderRadius: 8,
  cursor: 'pointer',
  transition: '0.4s',
  '&:hover': {
    background: '#cccccc99',
    transform: 'scale(1.1)',
  },
});
