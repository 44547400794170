import { useRecoilState } from 'recoil';

import eventAtom from '../atoms/event';

const useEvent = (): any => {
  const [event, setEvent] = useRecoilState(eventAtom);

  return { event, setEvent };
};

export default useEvent;
