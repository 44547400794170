import * as yup from 'yup';

export const initialFilterFormValues = {
  category: '',
  track: '',
  weight: '',
  gender: '',
  typeCategory: '',
};

export interface FilterFormProps {
  category: string;
  track: string;
  weight: string;
  gender: string;
  typeCategory: string;
}

export const validationSchema = yup.object({
  // event: yup.string().required('Campo obrigátorio'),
});
