import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { StyledRoot } from './styles';

import { getPublicBracket } from '../../../api/events';

import DetailsInfo from '../../../components/DetailsInfo';

import Stages from './Stages';

const EventAreasBrackets: React.FC = (): ReactElement => {
  const { eventId, categoryId } = useParams();

  const [event, setEvent] = useState<any>(null);
  const [category, setCategory] = useState<any>(null);

  const [eventAreasCategory, setEventAreasCategory] = useState<any>([]);

  const [isLoading, setLoading] = useState(true);

  const getBracketData = useCallback(async () => {
    try {
      setLoading(true);
      if (eventId && categoryId) {
        const data = await getPublicBracket(eventId, categoryId);

        setEvent(data?.event);

        setCategory(data?.category);

        setEventAreasCategory(data?.fights || []);
      }
    } catch (error) {
      toast(
        'Não foi possivel executar essa ação. Por favor, tente novamente!',
        { type: 'error' }
      );
    } finally {
      setLoading(false);
    }
  }, [categoryId, eventId]);

  useEffect(() => {
    getBracketData();
  }, [getBracketData]);

  return (
    <StyledRoot>
      <div style={{ width: '100%' }}>
        <DetailsInfo
          isLoading={isLoading}
          sections={[
            {
              rows: [
                {
                  key: 'name',
                  label: 'Nome',
                },
                {
                  key: 'categoryName',
                  label: 'Categoria',
                },
              ],
              data: {
                name: event?.name,
                categoryName: category?.categoryName,
              },
              title: 'Evento',
            },
          ]}
        />

        <Stages stages={eventAreasCategory} />
      </div>
    </StyledRoot>
  );
};

export default EventAreasBrackets;
