import React from 'react';
import {
  Bracket,
  Seed,
  SeedItem,
  SeedTeam,
  IRoundProps,
  IRenderSeedProps,
} from 'react-brackets';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Avatar, Badge } from '@mui/material';
import { formatOnlyHour } from '../../../../utils/date';

interface LosingProps {
  rounds: IRoundProps[];
}

const WiningBracket: React.FC<LosingProps> = ({ rounds: wining }) => {
  const RenderSeed = ({ seed, breakpoint }: IRenderSeedProps) => {
    const formatName = (name?: string, byeMatch?: boolean) => {
      if (name) {
        return name;
      }

      if (byeMatch) {
        return '-----------';
      }
      return '';
    };

    const getTeam = (teams: any[], index: number) => {
      return teams?.find((item) => item.order === index);
    };

    return (
      <Seed
        style={
          {
            // opacity: seed.bye_match ? 0.8 : 1,
          }
        }
        className="custom-border"
        mobileBreakpoint={breakpoint}
      >
        <SeedItem
          style={{
            width: 350,
            background: '#2B4755',
            position: 'relative',
          }}
        >
          <div
            style={{
              position: 'absolute',
              left: -18,
              bottom: 28,
              background: '#777',
              border: '1px solid #ccc',
              zIndex: 100,
              fontSize: 10,
              padding: 4,
              boxSizing: 'border-box',
              borderRadius: 12,
            }}
          >
            {seed.tag}
          </div>
          <div>
            <SeedTeam
              style={{
                justifyContent: 'flex-start',
                gap: 8,
                height: 36,
              }}
            >
              <div style={{ position: 'relative' }}>
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  badgeContent={
                    getTeam(seed?.teams, 0)?.win ? (
                      <CheckCircleIcon
                        style={{ width: 16, height: 16 }}
                        htmlColor="lightgreen"
                      />
                    ) : undefined
                  }
                >
                  <Avatar
                    alt={getTeam(seed?.teams, 0)?.name}
                    src={getTeam(seed?.teams, 0)?.image}
                    sx={{ width: 28, height: 28 }}
                    style={{
                      border: getTeam(seed?.teams, 0)?.win
                        ? '2px solid lightgreen'
                        : '2px solid #000',
                    }}
                  />
                </Badge>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                }}
              >
                <div
                  style={{
                    textAlign: 'left',
                    width: '300px',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {formatName(getTeam(seed?.teams, 0)?.name, seed.bye_match)}
                </div>
                <div
                  style={{
                    textAlign: 'left',
                    color: '#ccc',
                    fontSize: 12,

                    width: '200px',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {getTeam(seed?.teams, 0)?.team || ''}
                </div>
              </div>
            </SeedTeam>
            <div style={{ height: 1, background: '#ccc' }}></div>
            <SeedTeam
              style={{
                justifyContent: 'flex-start',
                gap: 8,
                height: 36,
                background: '#FFF',
              }}
            >
              <div style={{ position: 'relative' }}>
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  badgeContent={
                    getTeam(seed?.teams, 1)?.win ? (
                      <CheckCircleIcon
                        style={{ width: 16, height: 16 }}
                        htmlColor="lightgreen"
                      />
                    ) : undefined
                  }
                >
                  <Avatar
                    alt={getTeam(seed?.teams, 1)?.name}
                    src={getTeam(seed?.teams, 1)?.image}
                    sx={{ width: 28, height: 28 }}
                    style={{
                      border: getTeam(seed?.teams, 1)?.win
                        ? '2px solid lightgreen'
                        : '2px solid #000',
                    }}
                  />
                </Badge>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                }}
              >
                <div
                  style={{
                    textAlign: 'left',
                    width: '300px',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    color: '#000',
                  }}
                >
                  {formatName(getTeam(seed?.teams, 1)?.name, seed.bye_match)}
                </div>
                <div
                  style={{
                    textAlign: 'left',
                    color: '#000',
                    fontSize: 12,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {getTeam(seed?.teams, 1)?.team || ''}
                </div>
              </div>
            </SeedTeam>
          </div>
        </SeedItem>
        {/* <div style={{ height: 24, width: 5 }}></div> */}
        <div>{!!seed.date && formatOnlyHour(new Date(seed.date))}</div>
        {/* <div style={{ padding: 5, fontSize: 14 }}>26/08/2024 09:30</div> */}
      </Seed>
    );
  };

  return (
    <Bracket
      rounds={wining}
      renderSeedComponent={RenderSeed}
      swipeableProps={{
        enableMouseEvents: true,
        animateHeight: true,
        style: {
          padding: '0 50px 0 0',
        },
      }}
    />
  );
};

export default WiningBracket;
