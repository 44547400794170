/* eslint-disable no-plusplus */
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { Button, MenuItem, Select } from '@mui/material';
import { StyledItemCategory } from './styles';
import FilterOrganizeArea from './FilterOrganizeArea';
import { formatOnlyHour } from '../../utils/date';
import { useDebounce } from '../../hooks/useDebounce';

interface OrganizeAreasProps {
  categories: any[];
  startTime: string;
  eventAreas?: any[];
  areas: number;
  numberDays: number;
  filtersData?: {
    weight: string[];
    track: string[];
    category: string[];
  };
  handleSave: (data: any, generateFights: boolean) => void;
  isLoading: boolean;
  handleFinish: () => void;
}

const OrganizeAreas: React.FC<OrganizeAreasProps> = ({
  categories,
  eventAreas,
  areas,
  filtersData,
  numberDays,
  isLoading,
  handleSave,
  handleFinish,
}): ReactElement => {
  const [items, setItems] = useState<any[]>([]);
  const [areasObject, setAreasObject] = useState<any[]>([]);

  const [updatedAt, setUpdateAt] = useState<Date | undefined>(undefined);

  const [isUpdating, setUpdating] = useState(false);

  const [daySelected, setDaySelected] = useState('0');

  const areasDebounce = useDebounce(areasObject, 1000);

  const [days, setDays] = useState<number[]>([]);

  const [itemsSelecteds, setItemsSelecteds] = useState<any[]>([]);

  const [changes, setChanges] = useState<number[]>([]);

  const [filters, setFilters] = useState({
    category: '',
    track: '',
    weight: '',
    gender: '',
    typeCategory: '',
  });

  const handleSubmit = async (data: any) => {
    try {
      setUpdating(true);
      setUpdateAt(undefined);

      if (changes.length) {
        const dataToSubmit = data.filter((item: any) =>
          changes.includes(item.id)
        );

        await handleSave(dataToSubmit, true);
      }
    } catch {
      // eslint-disable-next-line no-console
      console.log('erro');
    } finally {
      setUpdateAt(new Date());
      setUpdating(false);
    }
  };

  // useEffect(() => {
  //   if (areasDebounce.length) {
  //     handleSubmit(areasDebounce);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [areasDebounce]);

  useEffect(() => {
    if (areas > 0 && eventAreas?.length && categories?.length) {
      let newItems = [...categories];
      const newDays: number[] = [];
      const newAreas = eventAreas.map((item) => {
        newDays.push(item.day);
        return {
          id: item.id,
          day: item.day,
          uuid: item.uuid,
          name: item.name,
          categories: item.categories?.length
            ? item.categories
                .map((category: any) => {
                  const categoryData = categories.find((itemCategory) => {
                    return itemCategory.categoryId === category.category;
                  });

                  newItems = newItems.filter(
                    (item) => item.categoryId !== category.category
                  );

                  return {
                    ...category,
                    ...categoryData,
                    order: category.order,
                  };
                })
                .sort((a: any, b: any) => {
                  return a.order - b.order;
                })
            : [],
        };
      });

      setDays([...new Set(newDays)].sort());

      setAreasObject(newAreas);
      setItems(newItems);
    }
  }, [areas, eventAreas, categories, numberDays]);

  const handleSelect = (item: any) => {
    const alreadyItem = itemsSelecteds.find(
      (data) => data.categoryId === item.categoryId
    );

    if (alreadyItem) {
      setItemsSelecteds(
        itemsSelecteds.filter((data) => data.categoryId !== item.categoryId)
      );
    } else {
      setItemsSelecteds([...itemsSelecteds, item]);
    }
  };

  const onDragStart =
    (item: any, originArray: number) => (event: React.DragEvent) => {
      event.dataTransfer.setData('category', JSON.stringify(item));
      event.dataTransfer.setData('originArray', String(originArray));
    };

  const handleDrop = (areaId: number) => (event: React.DragEvent) => {
    event.preventDefault();
    const data = event.dataTransfer.getData('category');
    const category = JSON.parse(data);
    const origin = event.dataTransfer.getData('originArray');
    const originArray = Number(origin);

    if (areaId === originArray) {
      return;
    }

    const alreadyHasCategory = itemsSelecteds.find(
      (item) => item.categoryId === category.categoryId
    );

    const itemsToMove = alreadyHasCategory
      ? itemsSelecteds
      : [...itemsSelecteds, category];

    const newitems = items.filter((item) => {
      const hasItem = itemsToMove.find(
        (itemToMove) => itemToMove.categoryId === item.categoryId
      );

      return !hasItem;
    });

    let newAreasObject = areasObject.map((item) => {
      return {
        ...item,
        categories: item.categories.filter((categoryOrigin: any) => {
          const dataItem = itemsToMove.find(
            (itemToMove) => itemToMove.categoryId === categoryOrigin.categoryId
          );

          return !dataItem;
        }),
      };
    });

    if (areaId === -1) {
      newitems.push(...itemsToMove);
    } else {
      newAreasObject = newAreasObject.map((item) => {
        if (item.id === areaId) {
          item.categories.push(...itemsToMove);
        }

        return item;
      });
    }

    setItemsSelecteds([]);

    setTimeout(() => {
      setAreasObject(newAreasObject);
      setItems(newitems);
    }, 50);

    const newChanges = [...changes];

    if (areaId !== -1) {
      newChanges.push(areaId);
    }

    if (originArray !== -1) {
      newChanges.push(originArray);
    }

    setChanges([...new Set(newChanges)]);
  };

  const allowDrop = (event: React.DragEvent) => {
    event.preventDefault();
  };

  const applyFilters = useCallback(
    (items: any) => {
      if (
        filters.category ||
        filters.gender ||
        filters.track ||
        filters.weight ||
        filters.typeCategory
      ) {
        const itemsFiltered = items.filter((item: any) => {
          let avaliable = true;
          if (filters.category && item.categoryName !== filters.category) {
            avaliable = false;
          }
          if (filters.gender && item.genderName !== filters.gender) {
            avaliable = false;
          }
          if (filters.weight && item.weightName !== filters.weight) {
            avaliable = false;
          }
          if (filters.track && item.trackName !== filters.track) {
            avaliable = false;
          }
          if (
            filters.typeCategory &&
            item.typeCategoryName !== filters.typeCategory
          ) {
            avaliable = false;
          }
          return avaliable;
        });

        return itemsFiltered;
      }

      return items;
    },
    [filters]
  );

  const formatName = (item: any): string => {
    return item.descriptionCategory;
  };

  const countNumberFight = (categories: any[]): number => {
    return categories.reduce(
      (prev, current) => {
        return Number(prev) + Number(current.numberFights);
      },
      [0]
    );
  };

  const filterByDay = (items: any[], day: number): any[] => {
    return items.filter((item) => item.day === day);
  };

  const renderObjectDay = useMemo(() => {
    if (typeof daySelected !== 'number') {
      return [days[0]];
    }

    return days.filter((item) => Number(item) === Number(daySelected));
  }, [days, daySelected]);

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        padding: '0px 24px 24px 24px',
        boxSizing: 'border-box',
      }}
    >
      <div style={{ textAlign: 'center', fontSize: 18 }}>Organizar áreas</div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          paddingRight: 16,
          gap: 12,
          boxSizing: 'border-box',
          alignItems: 'center',
        }}
      >
        {Boolean(updatedAt) && (
          <div style={{ color: '#777' }}>
            Salvo às {formatOnlyHour(updatedAt, true)}
          </div>
        )}
        {Boolean(isUpdating) && (
          <div style={{ color: '#777' }}>Atualizando...</div>
        )}

        {/* <Button
          color="primary"
          variant="contained"
          disabled={isLoading}
          size="small"
          onClick={() => handleSubmit(areasObject)}
          style={{
            letterSpacing: 1,
            fontSize: 14,
          }}
        >
          Salvar
        </Button> */}

        <Button
          color="primary"
          variant="contained"
          disabled={isLoading}
          size="small"
          onClick={() => handleSubmit(areasObject)}
          style={{
            letterSpacing: 1,
            fontSize: 14,
          }}
        >
          Gerar lutas
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={isLoading}
          size="small"
          onClick={() => handleFinish()}
          style={{
            letterSpacing: 1,
            fontSize: 14,
          }}
        >
          Visualizar áreas
        </Button>
      </div>

      {!!filtersData && (
        <div>
          <div>Filtros</div>
          <div>
            <FilterOrganizeArea
              categories={categories}
              filtersData={filtersData}
              handleSubmit={setFilters}
              hasFiltersApplied={Boolean(
                filters.category ||
                  filters.gender ||
                  filters.track ||
                  filters.weight ||
                  filters.typeCategory
              )}
            />
          </div>
        </div>
      )}

      <div style={{ paddingTop: 24 }}>
        <Select
          value={daySelected}
          onChange={(event) => setDaySelected(event.target.value)}
          style={{ width: 320, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}
        >
          {days.map((item) => (
            <MenuItem key={item} value={item}>
              Dia {item + 1}
            </MenuItem>
          ))}
        </Select>
      </div>

      <div
        style={{
          flexDirection: 'row',
          display: 'flex',
          gap: 12,
        }}
      >
        <div
          style={{
            width: 320,
            paddingTop: 24,
          }}
        >
          <div>Categorias</div>
          <div
            style={{
              border: '1px solid #ccc',
              borderRadius: 8,
              height: 340,
              marginTop: 12,
              overflowY: 'auto',
              width: 316,
            }}
            onDrop={handleDrop(-1)}
            onDragOver={allowDrop}
          >
            <div
              style={{
                display: 'flex',
                fontSize: 12,
                padding: 10,
                boxSizing: 'border-box',
              }}
            >
              <div
                style={{
                  width: 260,
                  boxSizing: 'border-box',
                }}
              >
                Categoria
              </div>
              <div
                style={{
                  width: 40,
                  textAlign: 'center',
                  boxSizing: 'border-box',
                }}
              >
                Lutas
              </div>
            </div>
            {applyFilters(items).map((item: any, index: number) => (
              <StyledItemCategory
                key={index}
                draggable
                onDragStart={onDragStart(item, -1)}
                onClick={() => handleSelect(item)}
                selected={
                  !!itemsSelecteds.find(
                    (data) => data.categoryId === item.categoryId
                  )
                }
              >
                <div style={{ width: 260 }}>{formatName(item)}</div>
                <div style={{ width: 40, textAlign: 'center' }}>
                  {item.numberFights}
                </div>
              </StyledItemCategory>
            ))}
          </div>
        </div>
        <div style={{ width: 'calc(100% - 340px)' }}>
          {renderObjectDay.map((day) => (
            <React.Fragment key={day}>
              <div
                style={{
                  width: '100%',
                  overflowX: 'auto',
                  display: 'flex',
                  gap: 12,
                  paddingTop: 24,
                }}
              >
                {filterByDay(areasObject, day).map((item, index) => (
                  <div key={index} style={{ minWidth: 320, width: 320 }}>
                    <div>{item.name}</div>
                    <div
                      onDrop={handleDrop(item.id)}
                      onDragOver={allowDrop}
                      style={{
                        border: '1px solid #ccc',
                        borderRadius: 8,
                        width: 320,
                        height: 340,
                        marginTop: 12,
                        overflowY: 'auto',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          fontSize: 12,
                          padding: 10,
                          boxSizing: 'border-box',
                        }}
                      >
                        <div
                          style={{
                            width: 260,
                            boxSizing: 'border-box',
                          }}
                        >
                          Categoria
                        </div>
                        <div
                          style={{
                            width: 40,
                            textAlign: 'center',
                            boxSizing: 'border-box',
                          }}
                        >
                          Lutas
                        </div>
                      </div>
                      {applyFilters(item.categories).map(
                        (category: any, indexCategory: number) => (
                          <StyledItemCategory
                            key={`${index}-${indexCategory}`}
                            draggable
                            onDragStart={onDragStart(category, item.id)}
                            onClick={() => handleSelect(category)}
                            selected={
                              !!itemsSelecteds.find(
                                (data) =>
                                  data.categoryId === category.categoryId
                              )
                            }
                          >
                            <div style={{ width: 260 }}>
                              {formatName(category)}
                            </div>
                            <div style={{ width: 40, textAlign: 'center' }}>
                              {category.numberFights}
                            </div>
                          </StyledItemCategory>
                        )
                      )}
                    </div>
                    <div>
                      {/* <div>Horario inicial: {formatTime(startTime)}</div> */}
                      <div>Nº de categorias: {item.categories.length}</div>
                      <div>
                        Nº de lutas: {countNumberFight(item.categories)}
                      </div>
                      {/* <div>Tempo total: {getTimeTotal(item.categories)}</div>
                      <div>
                        Estimativa final: {getFinalEta(item.categories)}
                      </div> */}
                    </div>
                  </div>
                ))}
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OrganizeAreas;
