/* eslint-disable no-plusplus */
import React, { ReactElement, useEffect, useMemo, useState } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { Avatar, Badge, MenuItem, Select } from '@mui/material';
import { StyledItem } from './styles';
import { formatOnlyHour } from '../../../utils/date';

interface AreasFightsProps {
  categories: any[];
  startTime: string;
  eventAreas?: any[];
  areas: number;
  numberDays: number;
  filters: any;
  daySelected: string;
  redirect: (item: any) => void;
}

const AreasFights: React.FC<AreasFightsProps> = ({
  categories,
  eventAreas,
  areas,
  numberDays,
  daySelected,
}): ReactElement => {
  const [areasObject, setAreasObject] = useState<any[]>([]);

  const [days, setDays] = useState<number[]>([]);

  useEffect(() => {
    if (areas > 0 && eventAreas?.length && categories?.length) {
      const newDays: number[] = [];

      const newAreas = eventAreas.map((item) => {
        newDays.push(item.day);

        const fights: any[] = [];

        item.categories.forEach((category: any) => {
          category.fights.forEach((fight: any) => {
            fights.push({
              ...fight,
              category,
            });
          });
        });

        return {
          id: `${item.day}-${item.order}`,
          day: item.day,
          name: item.name,
          categories: [],
          fights,
        };
      });

      setDays([...new Set(newDays)].sort());

      setAreasObject(newAreas);
    }
  }, [areas, eventAreas, categories, numberDays]);

  const filterByDay = (items: any[], day: number): any[] => {
    return items.filter((item) => item.day === day);
  };

  const sortArrayV2 = (array: any[]): any[] => {
    return array.sort((a, b) => {
      const timeA = new Date(a.date);
      const timeB = new Date(b.date);
      return timeA.getTime() - timeB.getTime();
    });
  };

  const mapStage = (stage: any): string => {
    const stageOrder: any = {
      'eighth-final': 'Oitavas de final',
      'quarter-final': 'Quartas de final',
      semifinal: 'Semifinal',
      bronze: 'Disputa de Terceiro',
      final: 'Final',
    };

    return stageOrder[stage] || stage;
  };

  const getAthlete = (athletes: any[], order: number): any => {
    return athletes.find((item) => item.order === order);
  };

  const isWinner = (athlete: any, result: any): boolean => {
    return result && athlete?.id === result?.eventAreaCategoryFightAthleteId;
  };

  const mapStatus = (
    status: any
  ): { label: string; color: string; background: string } => {
    const options: any = {
      pending: { label: 'Pendente', color: '#fff', background: '#404243' },
      completed: { label: 'Finalizada', color: '#000', background: '#66bb6b' },
      canceled: { label: 'Cancelada', color: '#fff', background: '#f44337' },
      programed: { label: 'Programada', color: '#000', background: '#90caf9' },
      stopped: {
        label: 'Aguardando resultado',
        color: '#000',
        background: '#ffa727',
      },
      in_progress: {
        label: 'Em andamento',
        color: '#000',
        background: '#29b6f6',
      },

      bye: {
        label: 'BYE',
        color: '#000',
        background: '#8bc34a',
      },
    };

    return options[status] || {};
  };

  const renderObjectDay = useMemo(() => {
    if (typeof daySelected !== 'number') {
      return [days[0]];
    }

    return days.filter((item) => Number(item) === Number(daySelected));
  }, [days, daySelected]);

  return (
    <div
      style={{
        flexDirection: 'row',
        display: 'flex',
        gap: 12,
      }}
    >
      <div style={{ width: '100%' }}>
        {renderObjectDay.map((day) => (
          <React.Fragment key={day}>
            <div
              style={{
                width: '100%',
                overflowX: 'auto',
                display: 'flex',
                gap: 18,
              }}
            >
              {filterByDay(areasObject, day).map((item, index) => (
                <div key={index} style={{ minWidth: 400, width: 400 }}>
                  <div>{item.name}</div>
                  <div
                    style={{
                      border: '1px solid #ccc',
                      boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                      borderRadius: 8,
                      width: 400,
                      minHeight: 100,
                      height: 'auto',
                      marginTop: 12,
                      boxSizing: 'border-box',
                      padding: 16,
                      gap: 8,
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    {sortArrayV2(item.fights)?.map((fight: any) => (
                      <StyledItem
                        key={fight.id}
                        status={fight.status}
                        // onClick={() => redirect(fight)}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <div
                            style={{
                              flex: 1,
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                // padding: '16px 16px 0px',
                                alignItems: 'center',
                                gap: 8,
                              }}
                            >
                              <div
                                style={{
                                  width: 12,
                                  height: 12,
                                  background: 'green',
                                  borderRadius: 12,
                                }}
                              />
                              <div style={{ fontWeight: 'bold' }}>
                                {fight.status === 'bye'
                                  ? 'XX:XX'
                                  : formatOnlyHour(fight.date)}
                              </div>
                              <div>
                                {mapStage(fight.stage)}{' '}
                                {!['final', 'bronze'].includes(fight.stage)
                                  ? fight.order + 1
                                  : ''}
                              </div>
                              {/* <div>({fight.stage})</div> */}
                            </div>
                            <div
                              style={{
                                // padding: '0px 16px',
                                fontSize: 12,
                              }}
                            >
                              {fight.category?.categoryName}
                            </div>
                          </div>
                          <div
                            style={{
                              border: '1px solid #ccc',
                              padding: '4px 8px',
                              boxSizing: 'border-box',
                              borderRadius: 8,
                              fontSize: 12,
                              background: mapStatus(fight?.status)?.background,
                              color: mapStatus(fight?.status)?.color,
                            }}
                          >
                            {mapStatus(fight?.status)?.label}
                          </div>
                        </div>

                        <div
                          style={{
                            // padding: '0px 16px',
                            margin: '4px 0px',
                            borderRadius: 4,
                            overflow: 'hidden',
                          }}
                        >
                          <div
                            style={{
                              background: '#2B4755',
                              color: '#fff',
                              padding: 8,
                              boxSizing: 'border-box',
                              height: 50,
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              gap: 8,
                            }}
                          >
                            <Badge
                              overlap="circular"
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                              }}
                              badgeContent={
                                isWinner(fight.athletes?.[0], fight.result) ? (
                                  <CheckCircleIcon
                                    style={{
                                      width: 16,
                                      height: 16,
                                      background: '#000',
                                      borderRadius: 100,
                                    }}
                                    htmlColor="lightgreen"
                                  />
                                ) : undefined
                              }
                            >
                              <Avatar
                                alt={getAthlete(fight.athletes, 0)?.nameAthlete}
                                src={getAthlete(fight.athletes, 0)?.photo}
                                sx={{ width: 30, height: 30 }}
                                style={{
                                  border: isWinner(
                                    fight.athletes?.[0],
                                    fight.result
                                  )
                                    ? '2px solid lightgreen'
                                    : '2px solid #000',
                                }}
                              />
                            </Badge>
                            <div>
                              <div
                                style={{
                                  fontSize: 14,
                                  width: 220,
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {getAthlete(fight.athletes, 0)?.nameAthlete ||
                                  '---'}
                              </div>
                              <div
                                style={{
                                  fontSize: 10,
                                  paddingTop: 4,
                                  width: 220,
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {getAthlete(fight.athletes, 0)?.gym || '---'}
                              </div>
                            </div>
                          </div>

                          <div
                            style={{
                              background: '#ddd',
                              color: '#000',
                              padding: 8,
                              boxSizing: 'border-box',
                              height: 50,
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              gap: 8,
                            }}
                          >
                            <Badge
                              overlap="circular"
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                              }}
                              badgeContent={
                                isWinner(fight.athletes?.[1], fight.result) ? (
                                  <CheckCircleIcon
                                    style={{
                                      width: 16,
                                      height: 16,
                                      background: '#000',
                                      borderRadius: 100,
                                    }}
                                    htmlColor="lightgreen"
                                  />
                                ) : undefined
                              }
                            >
                              <Avatar
                                alt={getAthlete(fight.athletes, 1)?.nameAthlete}
                                src={getAthlete(fight.athletes, 1)?.photo}
                                sx={{ width: 30, height: 30 }}
                                style={{
                                  border: isWinner(
                                    fight.athletes?.[1],
                                    fight.result
                                  )
                                    ? '2px solid lightgreen'
                                    : '2px solid #000',
                                }}
                              />
                            </Badge>
                            <div>
                              <div
                                style={{
                                  fontSize: 14,
                                  width: 220,
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {getAthlete(fight.athletes, 1)?.nameAthlete ||
                                  '---'}
                              </div>
                              <div
                                style={{
                                  fontSize: 10,
                                  paddingTop: 4,
                                  width: 220,
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {getAthlete(fight.athletes, 1)?.gym || '---'}
                              </div>
                            </div>
                          </div>

                          {/* <div
                            style={{
                              background: '#ddd',
                              color: '#000',
                              // borderBottom: '',
                              padding: 8,
                              boxSizing: 'border-box',
                              height: 50,
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              // margin: '2px 0px',
                            }}
                          >
                            <div style={{ overflow: 'hidden', fontSize: 14 }}>
                              {getAthlete(fight.athletes, 1)?.nameAthlete || '---'}
                            </div>
                            <div style={{ fontSize: 10, paddingTop: 4 }}>
                              {getAthlete(fight.athletes, 1)?.gym || '---'}
                            </div>
                          </div> */}
                        </div>
                      </StyledItem>
                    ))}
                  </div>
                  {/* <div>
                    <div>Horario inicial: {formatTime(startTime)}</div>
                    <div>Nº de categorias: {item.categories.length}</div>
                    <div>Nº de lutas: {countNumberFight(item.categories)}</div>
                    <div>Tempo total: 100 min</div>
                    <div>Estimativa final: 11:40</div>
                  </div> */}
                </div>
              ))}
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default AreasFights;
