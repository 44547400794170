import * as yup from 'yup';

export const initialEventFormValues = {
  event: '',
};

export interface EventFormProps {
  event: string;
}

export const validationSchema = yup.object({
  event: yup.string().required('Campo obrigátorio'),
});
