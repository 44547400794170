import 'react-toastify/dist/ReactToastify.css';

import React, { ReactElement } from 'react';
import { ToastContainer } from 'react-toastify';

import { HashRouter, Routes, Route } from 'react-router-dom';

import NotFound from './pages/public/NotFound';
import LoginPage from './pages/public/Login';
import PublicEventsPage from './pages/public/Events';
import PublicEventAreasPage from './pages/public/EventAreas';
import PublicEventAreasBracketsPage from './pages/public/EventAreasBrackets';

import DashboardPage from './pages/private/Dashboard';
import AreasOrganizePage from './pages/private/AreasOrganize';
import EventsPage from './pages/private/Events';
import EventDetailsPage from './pages/private/EventDetails';

import ScoreboardPage from './pages/private/Scoreboard';
import ScoreboardAreasPage from './pages/private/ScoreboardAreas';

import ScoreboardAreaDetailsPage from './pages/private/ScoreboardAreaDetails';

import ProtectedRoute from './Protected';
import ScoreboardSharePage from './pages/private/ScoreboardShare';
import PrivateRoute from './Private';

const App: React.FC = (): ReactElement => {
  return (
    <React.Fragment>
      <ToastContainer />

      <HashRouter>
        <Routes>
          <Route index element={<LoginPage />} />
          <Route path="events" element={<PublicEventsPage />} />
          <Route path="events/:eventId" element={<PublicEventAreasPage />} />
          <Route
            path="events/:eventId/brackets/:categoryId"
            element={<PublicEventAreasBracketsPage />}
          />

          <Route path="dash" element={<ProtectedRoute />}>
            <Route path="events" element={<EventsPage />} />

            <Route path="events/:eventId" element={<DashboardPage />}>
              <Route
                index
                path="home"
                element={
                  <PrivateRoute role="S">
                    <EventDetailsPage />
                  </PrivateRoute>
                }
              />
              <Route
                index
                path="areas"
                element={
                  <PrivateRoute role="S">
                    <AreasOrganizePage />
                  </PrivateRoute>
                }
              />
              <Route
                index
                path="scoreboard"
                element={<ScoreboardAreasPage />}
              />
              <Route
                index
                path="scoreboard/:areaId"
                element={<ScoreboardAreaDetailsPage />}
              />

              <Route
                path="scoreboard/:areaId/fight/:boardId"
                element={<ScoreboardPage />}
              />

              <Route path="*" element={<NotFound header />} />
            </Route>
          </Route>

          <Route path="scoreboard" element={<ProtectedRoute />}>
            <Route path="live" element={<ScoreboardSharePage />} />
          </Route>

          <Route
            path="events/:eventId/scoreboard/:areaId/fight/:boardId"
            element={<ScoreboardPage />}
          />
          {/* 
          <Route
            path="events/:eventId/scoreboard/:areaId/fight/:boardId/screen-share"
            element={<ScoreboardSharePage />}
          /> */}

          <Route path="*" element={<NotFound />} />
        </Routes>
      </HashRouter>
    </React.Fragment>
  );
};

export default App;
