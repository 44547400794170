import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  StyleContentCard,
  StyledContainer,
  StyledHeader,
  StyledOutlet,
  StyledRoot,
} from './styles';
import useAuthenticate from '../../../recoil/hooks/authenticate';
import { getEvents, selectEvent } from '../../../api/events';
import SelectEvent from '../../../components/SelectEvent';
import Card from '../../../components/Card';
import { EventFormProps } from '../../../components/SelectEvent/schema';
// import { toBase64WithObfuscation } from '../../../utils/hash';

const Events: React.FC = (): ReactElement => {
  const { authenticate, signin, decodeToken } = useAuthenticate();
  const navigate = useNavigate();

  const [events, setEvents] = useState([]);

  const getData = useCallback(async () => {
    try {
      if (authenticate.token) {
        const data = await getEvents(authenticate.token);

        setEvents(data?.events || []);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    }
  }, [authenticate.token]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleSubmit = async (values: EventFormProps) => {
    try {
      const data = await selectEvent(authenticate.token, values.event);

      signin(data.token);

      const teste = decodeToken(data.token);

      if (teste.role === 'S') {
        navigate(`/dash/events/${values.event}/home`);
      } else {
        navigate(`/dash/events/${values.event}/scoreboard`);
      }
    } catch (erro) {
      toast('Não foí possível executar essa ação, tente novamente!');
    }
  };

  return (
    <StyledRoot>
      <StyledContainer>
        <StyledHeader>
          Bem vindo, {authenticate?.user?.name || 'amigo'}
        </StyledHeader>
        <StyledOutlet>
          <StyleContentCard>
            <Card>
              <SelectEvent events={events} handleSubmit={handleSubmit} />
            </Card>
          </StyleContentCard>
        </StyledOutlet>
      </StyledContainer>
    </StyledRoot>
  );
};

export default Events;
