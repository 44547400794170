import styled from 'styled-components';

export const StyledRoot = styled('div')<{ nopadding: boolean }>(
  ({ nopadding }) => ({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',

    padding: nopadding ? 0 : 24,
    boxSizing: 'border-box',
  })
);
